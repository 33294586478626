import React, { useCallback, useMemo, useState } from 'react'
import { ActivityEventContextProvider } from './ActivityEventContextProvider'
import { ActivityEventWeeklyMissions } from './ActivityEventWeeklyMissions'
import { PoorMansSuspenseTicker, usePoorMansSuspenseTick } from 'hooks/usePoorMansSuspense'
import { Skeleton } from 'components/Skeleton'
import styled from 'styled-components/macro'
import { useEventsData } from '../../useEventsData'

interface ActivityEventFeedWeeklyMissionProps extends PoorMansSuspenseTicker {}

export const ActivityEventFeedWeeklyMission: React.FC<ActivityEventFeedWeeklyMissionProps> = ({ onReady }) => {
    const events = useEventsData()
    const [isProviderReady, setIsProviderReady] = useState(false)

    usePoorMansSuspenseTick(isProviderReady, onReady)

    const event = useMemo(() => {
        return events?.activityEvents.find(event => event.group === 'digital-mission-2023')
    }, [events])

    const onProviderReady = useCallback(() => {
        setIsProviderReady(true)
    }, [])

    if (!events) {
        return <Loader />
    }

    if (!event) {
        return null
    }

    return (
        <ActivityEventContextProvider
            fallback={<Loader />}
            event={event}
            allActivityEvents={events.activityEvents}
            onReady={onProviderReady}
        >
            <ActivityEventWeeklyMissions variant="feed" />
        </ActivityEventContextProvider>
    )
}

const Loader: React.FC = () => {
    return <StyledSkeleton variant="rect" />
}

const StyledSkeleton = styled(Skeleton)`
    height: 300px;
`
