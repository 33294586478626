import { api, MinimalAccount } from 'lib/api'
import { StoreState } from 'lib/store'
import { Middleware } from 'redux'
import { PRESENT } from 'redux-socket-client'
import {
    loadInventory,
    requestCommonStats,
    loadNotifications,
    requestWelcomeNotification,
    enableLeaderboard,
    disableLeaderboard,
    incrementActivityScore,
} from './actions'
import {
    DISABLE_ALL_LEADERBOARDS_FOR_PROJECT,
    LEADERBOARD,
    LEADERBOARD_FETCHED,
    LEADERBOARD_VERSION,
} from './constants'
import { FetchedLeaderboard, Leaderboard } from './models'

let requestedWelcome = false

export const liveopsMiddleware: Middleware =
    ({ dispatch, getState }) =>
    next =>
    action => {
        if (action.type === PRESENT) {
            dispatch(requestCommonStats())
            dispatch(loadInventory())
            dispatch(loadNotifications())

            if (!requestedWelcome) {
                requestedWelcome = true
                dispatch(requestWelcomeNotification())
                dispatch(incrementActivityScore('auth:login'))
            }
        }
        if (action.type === LEADERBOARD) {
            fetchLeaderboard(action.payload.leaderboard).then(leaderboard => {
                dispatch({ type: LEADERBOARD_FETCHED, payload: { leaderboard } })
            })
        }
        if (action.type === LEADERBOARD_VERSION) {
            const prev = action.payload.latest - 1
            if (prev) {
                dispatch(enableLeaderboard(action.payload.group, action.payload.stat, '' + prev))
            }
            dispatch(enableLeaderboard(action.payload.group, action.payload.stat))
        }
        if (action.type === DISABLE_ALL_LEADERBOARDS_FOR_PROJECT) {
            const state: StoreState = getState()
            for (const board of Object.values(state.shared.stats.leaderboard)) {
                if (board && board.group.includes(action.payload.projectId)) {
                    dispatch(disableLeaderboard(board.group, board.stat, board.version))
                }
            }
        }
        next(action)
    }

async function fetchLeaderboard(lb: Leaderboard): Promise<FetchedLeaderboard> {
    if (lb.items.length === 0) {
        return {
            ...lb,
            items: [],
        }
    }

    const users = await api.accounts.getUsers(lb.items.map(({ owner }) => owner))
    const usersById = users.reduce<Record<string, MinimalAccount>>((prev, curr) => {
        prev[curr.id] = curr
        return prev
    }, {})

    return {
        ...lb,
        items: lb.items.filter(item => usersById[item.owner]).map(item => ({ ...item, owner: usersById[item.owner] })),
    }
}
