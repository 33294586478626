import React from 'react'
import { SvgIcon, SvgIconProps } from '@material-ui/core'
import {
    FaBriefcase,
    FaTrophy,
    FaDiceD6,
    FaChalkboard,
    FaUser,
    FaHome,
    FaSignOutAlt,
    FaCog,
    FaPlus,
    FaBell,
    FaEdit,
    FaBars,
    FaUserPlus,
    FaDownload,
    FaCheck,
    FaLock,
    FaCode,
    FaExclamationCircle,
    FaInfoCircle,
    FaAngleRight,
    FaAngleLeft,
    FaCheckCircle,
    FaTimesCircle,
    FaMinusCircle,
    FaCookieBite,
    FaSearch,
    FaAngleDown,
    FaTrashAlt,
    FaClone,
    FaTimes,
    FaShareAlt,
    FaChevronLeft,
    FaImage,
    FaCamera,
    FaEllipsisH,
    FaChevronRight,
    FaStar,
    FaRegStar,
    FaUserFriends,
    FaStore,
    FaPlay,
    FaTrashRestore,
    FaSort,
    FaFilter,
    FaUserMinus,
    FaCalendarAlt,
    FaEye,
    FaRegEye,
    FaHeart,
    FaPoll,
    FaTh,
    FaThList,
    FaMapMarkerAlt,
    FaGraduationCap,
    FaUndoAlt,
    FaSync,
    FaCertificate,
    FaGhost,
    FaHatWizard,
    FaPencilAlt,
    FaUnlockAlt,
    FaRegPaperPlane,
    FaTshirt,
    FaDice,
    FaVoteYea,
    FaThumbsUp,
    FaGamepad,
    FaRegHeart,
    FaRegChartBar,
    FaLink,
    FaFastForward,
    FaQuestionCircle,
    FaThumbtack,
    FaCampground,
    FaAngleUp,
    FaTag,
    FaUpload
} from 'react-icons/fa'

export const UploadIcon = FaUpload
export const ManageIcon = FaBriefcase
export const AddIcon = FaPlus
export const SettingsIcon = FaCog
export const NotificationIcon = FaBell
export const HomeIcon = FaHome
export const UserIcon = FaUser
export const LogOutIcon = FaSignOutAlt
export const TrophyIcon = FaTrophy
export const ProjectIcon = FaDiceD6
export const ClassroomIcon = FaChalkboard
export const EditIcon = FaPencilAlt
export const RenameIcon = FaEdit
export const MenuIcon = FaBars
export const FollowIcon = FaUserPlus
export const UnfollowIcon = FaUserMinus
export const DownloadIcon = FaDownload
export const CheckIcon = FaCheck
export const LockIcon = FaLock
export const UnlockIcon = FaUnlockAlt
export const MixIcon = FaCode
export const WarningIcon = FaExclamationCircle
export const InfoIcon = FaInfoCircle
export const RightAngleIcon = FaAngleRight
export const LeftAngleIcon = FaAngleLeft
export const CheckCircleIcon = FaCheckCircle
export const TimesCircleIcon = FaTimesCircle
export const MinusCircleIcon = FaMinusCircle
export const CookieIcon = FaCookieBite
export const Searchicon = FaSearch
export const ChevronDownIcon = FaAngleDown
export const ChevronUpIcon = FaAngleUp
export const DeleteIcon = FaTrashAlt
export const DuplicateIcon = FaClone
export const CloseIcon = FaTimes
export const ShareIcon = FaShareAlt
export const BackIcon = FaChevronLeft
export const ForwardIcon = FaChevronRight
export const ImageIcon = FaImage
export const CameraIcon = FaCamera
export const MoreIcon = FaEllipsisH
export const FavoriteIcon = FaStar
export const FavoriteOutlineIcon = FaRegStar
export const CommunityIcon = FaUserFriends
export const StoreIcon = FaStore
export const PlayIcon = FaPlay
export const RestoreIcon = FaTrashRestore
export const SortIcon = FaSort
export const FilterIcon = FaFilter
export const EventsIcon = FaCalendarAlt
export const ViewIcon = FaEye
export const EyeIcon = FaRegEye
export const HeartIcon = FaHeart
export const HeartOutlineIcon = FaRegHeart
export const PlacementIcon = FaPoll
export const ScoreIcon = FaStar
export const GridIcon = FaTh
export const ListIcon = FaThList
export const LocationIcon = FaMapMarkerAlt
export const TrainerIcon = FaGraduationCap
export const ResetIcon = FaUndoAlt
export const RefreshIcon = FaSync
export const CertificateIcon = FaCertificate
export const GhostIcon = FaGhost
export const WizardHatIcon = FaHatWizard
export const MoveProductionIcon = FaRegPaperPlane
export const TshirtIcon = FaTshirt
export const DiceIcon = FaDice
export const VoteIcon = FaVoteYea
export const ThumbIcon = FaThumbsUp
export const GamePadIcon = FaGamepad
export const ChartIcon = FaRegChartBar
export const LinkIcon = FaLink
export const FastForwardIcon = FaFastForward
export const QuestionCircleIcon = FaQuestionCircle
export const PinIcon = FaThumbtack
export const CampIcon = FaCampground
export const TagIcon = FaTag

export const BloxIcon: React.FC<SvgIconProps> = props => (
    <SvgIcon viewBox="0 0 89.7 59.861" {...props} fontSize="inherit">
        <path
            d="M220.662,108.733l-.367-.092-6.473-4.407a3.535,3.535,0,0,0-2.02-.643h-6.014A3.489,3.489,0,0,1,202.3,100.1V78.435a3.535,3.535,0,0,1,3.489-3.535l28.094.6A20.8,20.8,0,0,1,241.5,77.1l5.692,2.341a23,23,0,0,0,8.768,1.744h21.3a3.489,3.489,0,0,1,3.489,3.489V100.1a3.489,3.489,0,0,1-3.489,3.489H229.476a3.489,3.489,0,0,0-1.974.643l-6.473,4.407Z"
            transform="translate(-202.3 -74.9)"
        />
        <path
            d="M220.762,172.843l-.367-.092-6.427-4.407a3.535,3.535,0,0,0-2.02-.643h-6.06a3.489,3.489,0,0,1-3.489-3.489V148.788a3.489,3.489,0,0,1,3.489-3.489h6.289a1.7,1.7,0,0,1,.964.275l6.243,4.269a2.433,2.433,0,0,0,1.377.413,2.571,2.571,0,0,0,1.423-.413l6.243-4.269a1.7,1.7,0,0,1,.964-.275h59.173a3.489,3.489,0,0,1,3.489,3.489v15.424a3.489,3.489,0,0,1-3.489,3.489H229.622a3.535,3.535,0,0,0-2.02.643l-6.427,4.407Z"
            transform="translate(-202.354 -112.982)"
        />
    </SvgIcon>
)
export const SwearIcon: React.FC<SvgIconProps> = props => (
    <SvgIcon viewBox="0 0 327.8 229.2" fontSize="inherit" {...props}>
        <path d="M208.4 101.4l10.2-.7 1.5-12-10.2.8zM107.7 113.1c-.8 1.4-1.1 2.8-1 4.3a8 8 0 003.4 5.7 11 11 0 007.7 2.1 22 22 0 0011-4.8c-4.2-2.7-9.6-6.9-16.3-12.3-2.5 2-4 3.7-4.8 5zM118 91.1c2.3-1.5 4-3 5.3-4.7 1.2-1.7 1.7-3.2 1.6-4.6a4.8 4.8 0 00-2-3.5 6.6 6.6 0 00-4.6-1.2c-2 .1-3.4.8-4.5 2a5.2 5.2 0 00-1.6 4c.2 2 2 4.6 5.8 8z" />
        <path d="M327.7 167.4l-12-160.2c-.3-4.3-4-7.5-8.3-7.2L7.2 22.5c-4.3.3-7.5 4-7.2 8.3L12 191c.3 4.3 4 7.5 8.3 7.2l300.3-22.5c4.2-.3 7.4-4 7.1-8.3zM47 142.7l-9.5.7L49 72l9.6-.7-11.8 71.4zm105.9-8c-2-.8-4-1.8-6-3l-5.6-2.9a37.8 37.8 0 01-9.5 5.5 53.8 53.8 0 01-15.3 3.2c-9 .6-15.9-.7-20.5-4.2s-7.3-7.8-7.7-13.2c-.3-3.8.7-7.6 3-11.2 2.4-3.6 6.4-6.8 12.1-9.7a33.5 33.5 0 01-5.6-7.4c-1.1-2.1-1.7-4.3-2-6.6a15 15 0 014.7-12.3c3.4-3.4 8.9-5.4 16.4-6 7.6-.5 13.3.6 17 3.3 3.9 2.7 6 6 6.2 10.2.2 2.3-.2 4.6-1.1 6.8-1 2.2-2.1 4-3.6 5.5-1.4 1.4-4 3.6-7.8 6.5 3.5 3 7.2 5.9 11 8.4.8-2 1.5-4 2-6.2l15 2.2a56.2 56.2 0 01-4.3 12.7l7.4 4.3 3.5 1.8-9.3 12.3zm77.3-22l-2 16.5-13.2 1 2-16.6-10.1.8-2 16.5-13.2 1 2-16.5-6.3.4-1-12.7 9-.7 1.5-12-11.3 1-1-12.9 13.9-1 2-16.8 13.2-1-2 16.8 10-.7 2.2-16.8 13-1-2 16.8 6.4-.5 1 12.8-9 .6-1.5 12 11.3-.9 1 12.8-13.9 1zm37.7-55.9l20.1-1.5 1.2 15.5-1.6 31.3-12.4 1-6.1-30.8-1.2-15.5zm5.8 67.8l-1.3-16.6 18.8-1.4 1.3 16.6-18.8 1.4z" />
        <path d="M67.7 226.7l27.9-38.8c2.7-3.8.3-9.2-4.4-9.7l-47-4.9a6.1 6.1 0 00-6.3 8.6L57 225.6c2 4.3 7.9 5 10.6 1z" />
    </SvgIcon>
)
