import * as React from 'react'
import ReactDOM from 'react-dom'
import { nameStyles } from '../PdfTemplates'
import '../certificates.css'
import { api } from 'lib/api'

interface PdfTemplateProps {
    school?: string
    finishedAt: string
    name: string
    locale: string
    id: string
    translations: {
        section_first?: string
        section_second?: string
        blurb?: string
    }
    year?: number
}

export const PdfTemplateDrawingContestScoolRound: React.FC<PdfTemplateProps> = props => {
    return <PdfTemplateDrawingContest variant="drawingContest2020School" {...props} />
}

export const PdfTemplateDrawingContestNational: React.FC<PdfTemplateProps> = props => {
    return <PdfTemplateDrawingContest variant="drawingContest2020National" {...props} />
}

export const PdfTemplateDrawingContestInternationalParticipant: React.FC<PdfTemplateProps> = props => {
    return <PdfTemplateDrawingContest variant="drawingContest2020InternationalParticipant" {...props} />
}

export const PdfTemplateDrawingContestInternationalJuniorDesigner: React.FC<PdfTemplateProps> = props => {
    return <PdfTemplateDrawingContest variant="drawingContest2020International" {...props} />
}

export const PdfTemplateDrawingContest2021Base: React.FC<PdfTemplateProps> = props => {
    return <PdfTemplateDrawingContest variant="drawingContest2021" {...props} />
}

export const PdfTemplateDrawingContest2022: React.FC<PdfTemplateProps> = props => {
    return <PdfTemplateDrawingContest variant="drawingContest2022" {...props} />
}

export const PdfTemplateDrawingContest2023: React.FC<PdfTemplateProps> = props => {
    return <PdfTemplateDrawingContest year={2023} variant="drawingContest2023" {...props} />
}

export const PdfTemplateDrawingContest: React.FC<
    PdfTemplateProps & {
        variant:
            | 'drawingContest2020School'
            | 'drawingContest2020National'
            | 'drawingContest2020InternationalParticipant'
            | 'drawingContest2020International'
            | 'drawingContest2021'
            | 'drawingContest2022'
            | 'drawingContest2023'
    }
> = ({ translations: translationsRaw, year, finishedAt, variant, name, locale, id }) => {
    const translations = year ? {
        section_first: (translationsRaw.section_first || '').replace('$YEAR$', ''+year).replace('$NEXT_YEAR$', ''+(year+1)),
        section_second: (translationsRaw.section_second || '').replace('$YEAR$', ''+year).replace('$NEXT_YEAR$', ''+(year+1)),
        blurb: (translationsRaw.blurb || '').replace('$YEAR$', ''+year).replace('$NEXT_YEAR$', ''+(year+1)),
    } : translationsRaw;

    if (variant === 'drawingContest2020International') {
        return ReactDOM.createPortal(
            <div className={'templateWrap'}>
                <div id={id} className={'templateRoot'}>
                    <img className={'templateFrame'} src={getFrameSrc(variant, locale)} alt="" />
                    <div className={'templateText'} style={{ top: name.length > 22 ? '160mm' : '185mm' }}>
                        <div className={'templateCustomName'} style={nameStyles(name)}>
                            {name}
                        </div>
                        {translations.blurb && (
                            <div
                                style={{ marginTop: '18mm', maxWidth: translations.blurb ? '330mm' : '200mm' }}
                                className={'templateCustomBlurp'}
                                dangerouslySetInnerHTML={{ __html: translations.blurb }}
                            ></div>
                        )}
                        {translations.section_first && (
                            <div
                                style={{ marginTop: '20mm', maxWidth: translations.blurb ? '300mm' : '200mm' }}
                                className={'templateSection'}
                                dangerouslySetInnerHTML={{ __html: translations.section_first }}
                            ></div>
                        )}
                        {translations.section_second && (
                            <div
                                style={{ marginTop: '18mm', maxWidth: translations.blurb ? '250mm' : '200mm' }}
                                className={'templateSection'}
                                dangerouslySetInnerHTML={{ __html: translations.section_second }}
                            ></div>
                        )}
                    </div>
                </div>
            </div>,
            document.body,
        )
    } else {
        return ReactDOM.createPortal(
            <div className={'templateWrap'}>
                <div id={id} className={'templateRoot'}>
                    <img
                        className={'templateFrame'}
                        src={getFrameSrc(
                            variant === 'drawingContest2020InternationalParticipant'
                                ? 'drawingContest2020International'
                                : variant,
                            locale,
                        )}
                        alt=""
                    />
                    <div className={'templateText'} style={{ top: translations.blurb ? '160mm' : '185mm' }}>
                        <div className={'templateCustomName'} style={nameStyles(name)}>
                            {name}
                        </div>
                        {translations.section_first && (
                            <div
                                style={{ marginTop: '20mm', maxWidth: translations.blurb ? '340mm' : '200mm' }}
                                className={'templateSection'}
                                dangerouslySetInnerHTML={{ __html: translations.section_first }}
                            ></div>
                        )}
                        {translations.blurb && (
                            <div
                                style={{ marginTop: '18mm', maxWidth: translations.blurb ? '340mm' : '200mm' }}
                                className={'templateCustomBlurp'}
                                dangerouslySetInnerHTML={{ __html: translations.blurb }}
                            ></div>
                        )}
                        {translations.section_second && (
                            <div
                                style={{ marginTop: '18mm', maxWidth: translations.blurb ? '340mm' : '200mm' }}
                                className={'templateSection'}
                                dangerouslySetInnerHTML={{ __html: translations.section_second }}
                            ></div>
                        )}
                    </div>
                </div>
            </div>,
            document.body,
        )
    }
}

type FrameType =
    | 'drawingContest2020School'
    | 'drawingContest2020National'
    | 'drawingContest2020International'
    | 'drawingContest2021'
    | 'drawingContest2022'
    | 'drawingContest2023';

const getFrameSrc = (type: FrameType, locale: string) => {
    return `${api.baseUrl}/certificates/frame-image-for-kind?kind=${type}&locale=${locale}`
}
