import { EmptyState } from 'components/EmptyState'
import { ErrorMessage } from 'components/ErrorMessage'
import { Loader } from 'components/Loader'
import { useTranslate } from 'lib/i18n/useTranslate'
import React from 'react'
import styled from 'styled-components/macro'
import { useMyChallengeSetsQuery } from '../data/scoolcodeChallengeQueries'
import { ScoolcodeChallengeCard } from './ScoolcodeChallengeCard'

interface ScoolcodeChallengeListProps {}

export const ScoolcodeChallengeList: React.FC<ScoolcodeChallengeListProps> = () => {
    const { data: challengeSets, status } = useMyChallengeSetsQuery()
    const t = useTranslate()

    if (status === 'error') {
        return <ErrorMessage />
    }

    if (!challengeSets) {
        return <Loader />
    }

    if (challengeSets.data.length === 0) {
        return <EmptyState>{t('scChallenges::noResults')}</EmptyState>
    }

    return (
        <Root>
            {challengeSets.data.map(challengeSet => (
                <ScoolcodeChallengeCard challengeSet={challengeSet} key={challengeSet.id} />
            ))}
        </Root>
    )
}

const Root = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
    gap: ${props => props.theme.spacing(6)};
    @media (max-width: 400px) {
        gap: ${props => props.theme.spacing(4)};
        grid-template-columns: repeat(auto-fill, minmax(9rem, 1fr));
    }
`
