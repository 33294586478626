import { ErrorBoundary } from 'components/ErrorBoundary'
import { useTranslate } from 'lib/i18n/useTranslate'
import { markNotificationAsDisplayed, readNotification } from 'lib/liveops'
import { useSelector } from 'lib/store'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { shiftQueue } from '../store/dialogQueueActions'
import { QueuedDialog } from './QueuedDialog'
// import { AttendedOpenDayDialog } from './AttendedOpenDayDialog'
import { OnboardingAchievementUnlockedDialog } from './OnboardingAchievementUnlockedDialog'
import { OnboardingPointsReachedDialog } from './OnboardingPointsReachedDialog'

export const DialogQueueManager: React.FC = () => {
    const dialogQueue = useSelector(state => state.dialogQueue.queue)
    const isPaused = useSelector(state => state.dialogQueue.isPaused)
    const dispatch = useDispatch()
    const currentDialog = isPaused ? undefined : dialogQueue[0]
    const t = useTranslate()

    const next = () => {
        dispatch(shiftQueue())
    }

    useEffect(() => {
        if (currentDialog) {
            dispatch(markNotificationAsDisplayed(currentDialog.notificationId))
            dispatch(readNotification(currentDialog.notificationId))
        }
    }, [currentDialog, dispatch])

    return (
        <ErrorBoundary silent>
            {dialogQueue.map(dialogData => {
                const key = dialogData.notificationId
                const open = dialogData === currentDialog

                switch (dialogData.type) {
                    case 'onboarding-achievement-unlocked':
                        return (
                            <OnboardingAchievementUnlockedDialog
                                key={key}
                                open={open}
                                onClose={next}
                                data={dialogData}
                            />
                        )

                    case 'onboarding-points-reached':
                        return (
                            <OnboardingPointsReachedDialog
                                key={key}
                                open={dialogData === currentDialog}
                                onClose={next}
                            />
                        )

                    case 'general':
                        return (
                            <QueuedDialog
                                key={key}
                                title={t(dialogData.title)}
                                description={t(dialogData.body, dialogData.bodyInterpolation)}
                                open={open}
                                image={dialogData.image ? { url: dialogData.image, disablePadding: true } : undefined}
                                onClose={next}
                                disableConfetti={dialogData.disableConfetti}
                                button={
                                    dialogData.button
                                        ? { label: t(dialogData.button.label), to: dialogData.button.to }
                                        : undefined
                                }
                            />
                        )

                    // case 'attended-open-day':
                    //     return <AttendedOpenDayDialog key={key} open={dialogData === currentDialog} onClose={next} />

                    default:
                        return null
                }
            })}
        </ErrorBoundary>
    )
}
