import cosmosCrossingThumbnail from './thumbnails/cosmos-crossing.mp4'
import galaxyJumpThumbnail from './thumbnails/galaxy-jump.mp4'
import spaceRaceThumbnail from './thumbnails/space-race.mp4'
import asteroidsThumbnail from './thumbnails/asteroids.mp4'
import spcaeTrapThumbnail from './thumbnails/space-trap.mp4'
import spacecraftEnergizerThumbnail from './thumbnails/spacecraft-energizer.mp4'
import laboratoryEscapeThumbnail from './thumbnails/laboratory-escape.mp4'
import robotOlympicsThumbnail from './thumbnails/robot-olympics.mp4'
import planetEvolutionThumbnail from './thumbnails/planet-evolution.mp4'
import maniFoldThumbnail from './thumbnails/manifold_trailer.mp4'
import balloonBlasterThumbnail from './thumbnails/balloon-blaster.mp4'
import additionThumbnail from './thumbnails/addition.mp4'
import witcherThumbnail from './thumbnails/witcher.mp4'
import santaThumbnail from './thumbnails/santas-crossing.mp4'
import cloneHuntThumbnail from './thumbnails/clone-hunt.mp4'
import cloneHuntDCThumbnail from './thumbnails/clonehuntDC.mp4'
import candyRushThumbnail from './thumbnails/candy-rush.mp4'
import stationDefenseThumbnail from './thumbnails/station-defense.mp4'
import mementoThumbnail from './thumbnails/memento.mp4'
import bounceBattleThumbnail from './thumbnails/bounce-battle.mp4'
import fusionGrow from './thumbnails/fusion-grow.mp4'

import { LocalisationKey } from 'lib/i18n'
import { Grant, hasGrant } from 'lib/grants'
import { UserState } from 'contexts/UserContext'
//import placeholder from './thumbnails/placeholder.jpg'
import { createEnvironmentVariable } from 'utils/env'

interface Stat {
    name: string
    i18nKey: LocalisationKey
}

export interface HighScoreGame {
    title: LocalisationKey
    projectId: string
    thumbnail: { type: 'video'; src: string } | { type: 'image'; src: string }
    description: LocalisationKey
    hiddenStats?: Array<string>
    stats?: Array<Stat>
    overlayColor?: string
    grantRequiredToUnlock?: Grant
    grantRequiredToDisplay?: Grant
    recommended?: boolean
}

export interface HighScoreGameWithLockedFlag extends HighScoreGame {
    locked: boolean
}

export const getHighscoreGames = (user: UserState): HighScoreGameWithLockedFlag[] => {
    return highScoreGames
        .filter(game => (game.grantRequiredToDisplay ? hasGrant(user.grants)(game.grantRequiredToDisplay) : true))
        .map(game => ({
            ...game,
            locked: game.grantRequiredToUnlock ? !hasGrant(user.grants)(game.grantRequiredToUnlock) : false,
        }))
        .sort((a, b) => (a.locked === b.locked ? 0 : a.locked ? 1 : -1))
}

const commonStats: Array<Stat> = [
    { name: 'Easy', i18nKey: 'general::easy' },
    { name: 'Medium', i18nKey: 'general::medium' },
    { name: 'Hard', i18nKey: 'general::hard' },
]

export const SPACECRAFT_ENERGIZER_PROJECT_ID = createEnvironmentVariable({
    dev: '607ff55a87aa7864061900ac',
    beta: '60bd6c91ca8f711fbe3ca0f9',
    prod: '60bd6c91ca8f711fbe3ca0f9',
})

export const LABORATORYESCAPE_PROJECT_ID = createEnvironmentVariable({
    dev: '60cb13afa60e848c642ff978',
    beta: '60cb13d60e62464d649294a2',
    prod: '60cb13d60e62464d649294a2',
})

export const ROBOTOLYMPICS_PROJECT_ID = createEnvironmentVariable({
    dev: '60def498cff1901a0c6d42dc', //todo when dev game is rdy
    beta: '6103f1d88bbc5a655ed0db10',
    prod: '6103f1d88bbc5a655ed0db10',
})

export const PLANETEVOLUTION_PROJECT_ID = createEnvironmentVariable({
    dev: '611e2094cceee92225bfd34a',
    beta: '611e1edd755d6f66026272f7',
    prod: '611e1edd755d6f66026272f7',
})

export const MANIFOLD_PROJECT_ID = createEnvironmentVariable({
    dev: '61814a603dd14945d320e634',
    beta: '61853e03a09d7041c84a8690',
    prod: '61853e03a09d7041c84a8690',
})

export const highScoreGames: HighScoreGame[] = [
    {
        projectId: '661e30862577322aa391a488',
        title: 'highScoreFusionGrow::title',
        description: 'highScoreFusionGrow::description',
        thumbnail: { type: 'video', src: fusionGrow },
        stats: [
            { name: 'Easy', i18nKey: 'general::easy' },
            { name: 'Extreme', i18nKey: 'extreme' as LocalisationKey }
        ]
    },
    {
        projectId: '650bffaab51af76242680265',
        title: 'highScoreCandyRush::title',
        description: 'highScoreCandyRush::description',
        thumbnail: { type: 'video', src: candyRushThumbnail },
        stats: commonStats
    },
    {
        projectId: '641034616cccdb32c2273c3c',
        title: 'highScoreCloneHunterSEDC22::title',
        description: 'highScoreCloneHunterSEDC22::description',
        thumbnail: { type: 'video', src: cloneHuntDCThumbnail }
    },
    {
        projectId: '64a3c9c49a4b9369361f4691',
        title: 'highScoreBounce::title',
        description: 'highScoreBounce::description',
        thumbnail: { type: 'video', src: bounceBattleThumbnail },
        stats: commonStats,
    },
    {
        projectId: '64539e8c4bec3a816f774b30',
        title: 'highScoreMemento::title',
        description: 'highScoreMemento::description',
        thumbnail: { type: 'video', src: mementoThumbnail },
        stats: commonStats,
    },
    {
        projectId: '63b4188d69715204ffb0bf27',
        title: 'highScoreStationDefense::title',
        description: 'highScoreStationDefense::description',
        thumbnail: { type: 'video', src: stationDefenseThumbnail },
        stats: commonStats,
    },
    {
        projectId: '637d27d88e92993b90409cb3',
        title: 'highScoreCloneHunter::title',
        description: 'highScoreCloneHunter::description',
        thumbnail: { type: 'video', src: cloneHuntThumbnail },
        stats: commonStats,
    },
    {
        projectId: '638dee822c35cd0710bb51ca',
        title: 'HighscoreGame::SantasCrossing::name',
        description: 'HighscoreGameSantasCrossing::description',
        thumbnail: { type: 'video', src: santaThumbnail },
        overlayColor: '#6c7ec4',
    },
    {
        projectId: '6357943f0b2ab57c6863c9d7',
        title: 'highScoreWitcher::title',
        description: 'highScoreWitcher::description',
        thumbnail: { type: 'video', src: witcherThumbnail },
        stats: [
            { name: 'WitchAdvanturePointEasy', i18nKey: 'general::easy' },
            { name: 'WitchAdvanturePointMedium', i18nKey: 'general::medium' },
            { name: 'WitchAdvanturePointHard', i18nKey: 'general::hard' },
        ],
        overlayColor: '#2a032b',
    },
    {
        projectId: '6336e0ce62be3b2821ec5e94',
        title: 'highScoreNumberAddition::title',
        description: 'highScoreNumberAddition::description',
        thumbnail: { type: 'video', src: additionThumbnail },
        stats: commonStats,
    },
    {
        projectId: '62d1508e461d0f1298936dc8',
        thumbnail: { type: 'video', src: balloonBlasterThumbnail },
        title: 'highScoreBalloon::title',
        description: 'highScoreBalloon::description',
        overlayColor: '#a550b9',
        stats: [
            { name: 'BalloonBlasterHighScoreEasy', i18nKey: 'general::easy' },
            { name: 'BalloonBlasterHighScoreNormal', i18nKey: 'general::medium' },
            { name: 'BalloonBlasterHighsScoreHard', i18nKey: 'general::hard' },
        ],
    },
    {
        title: 'highScoreManifoldUnlocker::title',
        projectId: MANIFOLD_PROJECT_ID,
        thumbnail: { type: 'video', src: maniFoldThumbnail },
        description: 'highScoreManifoldUnlocker::description',
        grantRequiredToDisplay: { product: 'edup.universe', feature: 'content.among-us' },
        recommended: true,
        hiddenStats: ['UnlockExtreme'],
        stats: [
            {
                name: 'Normal',
                i18nKey: 'general::normal' as LocalisationKey,
            },
            {
                name: 'Hard',
                i18nKey: 'general::hard' as LocalisationKey,
            },
            {
                name: 'Extreme',
                i18nKey: 'Extreme' as LocalisationKey,
            },
        ],
    },
    {
        title: 'digitalmission::PlanetEvolution::name',
        projectId: PLANETEVOLUTION_PROJECT_ID,
        thumbnail: { type: 'video', src: planetEvolutionThumbnail },
        description: 'digitalmission::PlanetEvolution::description',
        stats: [
            {
                name: 'PlanetEvolutionHSGsmall',
                i18nKey: '3x3' as LocalisationKey,
            },
            {
                name: 'PlanetEvolutionHSGmedium',
                i18nKey: '3x4' as LocalisationKey,
            },
            {
                name: 'PlanetEvolutionHSGlarge',
                i18nKey: '4x4' as LocalisationKey,
            },
        ],
        grantRequiredToUnlock: { product: 'edup.universe', feature: 'content.challenges.onboarding-points' },
    },
    {
        title: 'digitalmission::RobotOlympics::name',
        projectId: ROBOTOLYMPICS_PROJECT_ID,
        thumbnail: { type: 'video', src: robotOlympicsThumbnail },
        description: 'digitalmission::RobotOlympics::description',
        stats: [
            {
                name: 'Easy',
                i18nKey: 'general::easy',
            },
            {
                name: 'Normal',
                i18nKey: 'general::medium',
            },
            {
                name: 'Hard',
                i18nKey: 'general::hard',
            },
        ],
        overlayColor: '#9bbcc0',
        recommended: true,
    },
    {
        title: 'digitalmission::LaboratoryEscape::name',
        projectId: LABORATORYESCAPE_PROJECT_ID,
        thumbnail: {
            type: 'video',
            src: laboratoryEscapeThumbnail,
        },
        overlayColor: '#9bbcc0',
        description: 'digitalmission::LaboratoryEscape::description',
        grantRequiredToUnlock: { product: 'edup.universe', feature: 'content.challenges.open-day-participation' },
    },
    {
        title: 'digitalMission2021::achivements::NumberCrush::name',
        projectId: SPACECRAFT_ENERGIZER_PROJECT_ID,
        thumbnail: { type: 'video', src: spacecraftEnergizerThumbnail },
        description: 'digitalmission::NumberCrush::description',
        stats: [
            {
                name: 'Normal',
                i18nKey: 'Normal' as LocalisationKey,
            },
            {
                name: 'Hard',
                i18nKey: 'general::hard',
            },
        ],
        overlayColor: '#21464f',
    },
    {
        title: 'digitalmission::CosmosCrossing::name',
        projectId: '5f06c5765289ad0009c500b2',
        thumbnail: { type: 'video', src: cosmosCrossingThumbnail },
        description: 'digitalmission::CosmosCrossing::description',
        grantRequiredToUnlock: { product: 'edup.universe', feature: 'content.challenges.onboarding-points' },
    },
    {
        title: 'digitalmission::GalaxyJump::name',
        projectId: '5eec6536e6fba7000a75be0c',
        thumbnail: { type: 'video', src: galaxyJumpThumbnail },
        description: 'digitalmission::GalaxyJump::description',
        stats: commonStats,
        overlayColor: '#2f2847',
        recommended: true,
    },
    {
        title: 'digitalmission::spaceRace::name',
        projectId: '5ed8e0e42b197b000a12f9a5',
        thumbnail: { type: 'video', src: spaceRaceThumbnail },
        description: 'digitalmission::spaceRace::description',
        overlayColor: '#2f2847',
        grantRequiredToUnlock: { product: 'edup.universe', feature: 'content.challenges.open-day-participation' },
    },
    {
        title: 'project::5df3800063ca30001d3cd8f3::title',
        projectId: '5df3800063ca30001d3cd8f3',
        thumbnail: { type: 'video', src: asteroidsThumbnail },
        description: 'project::5df3800063ca30001d3cd8f3::description',
    },
    {
        title: 'project::5d43f3cdfe37a00019cae728::title',
        projectId: '5d43f3cdfe37a00019cae728',
        thumbnail: { type: 'video', src: spcaeTrapThumbnail },
        description: 'project::5d43f3cdfe37a00019cae728::description',
        stats: commonStats,
        grantRequiredToUnlock: { product: 'edup.universe', feature: 'content.challenges.onboarding-points' },
    },
]
