import { Dialog } from 'components/dialog'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components/macro'
import { api } from 'lib/api'
import { Loader } from 'components/Loader'
import { Paper } from 'components/Paper'
import { useInvalidateQuizResults } from './home-quiz/homeQuizQueries'
import { useInvalidateClassroomQuizResults } from './quizQueries'
import { Theme, useMediaQuery } from '@material-ui/core'
import { runtimeEnv } from 'utils/env'
import { useModal } from 'lib/router/modals/modalContext'
import { usePauseDialogQueue } from 'features/dialog-queue'

type Variant = 'self-host' | 'classroom'

interface QuizIframeProps {
    variant: Variant
}

const QuizIframe: React.FC<QuizIframeProps> = ({ variant }) => {
    const { invalidateQuizSetResults } = useInvalidateQuizResults()
    const { invalidateClassroomQuizResults } = useInvalidateClassroomQuizResults()
    const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down('xs'), { noSsr: true })
    const { closeModal } = useModal()
    const [hideClose, setHideClose] = React.useState(false)
    usePauseDialogQueue()

    const close = React.useCallback(() => {
        if (variant === 'self-host') {
            invalidateQuizSetResults()
        } else {
            invalidateClassroomQuizResults()
        }
        closeModal()
    }, [invalidateQuizSetResults, invalidateClassroomQuizResults, variant, closeModal])

    useEffect(() => {
        const onMessage = (event: MessageEvent) => {
            if (event.origin !== runtimeEnv.REACT_APP_QUIZ_URL) return

            if (event.data.type === 'ZOOMIN') {
                setHideClose(true)
            }

            if (event.data.type === 'ZOOMOUT') {
                setHideClose(false)
            }
        }

        window.addEventListener('message', onMessage)

        return () => {
            window.removeEventListener('message', onMessage)
        }
    }, [])

    return (
        <Dialog
            fullScreen={isMobile}
            disableEscapeKeyDown
            disableBackdropClick
            open
            onClose={close}
            fullWidth
            maxWidth="xl"
            PaperComponent={StyledPaper}
            closeButtonProps={{ style: { color: '#fff' } }}
            hideCloseButton={hideClose}
        >
            <IframeContent variant={variant} handleClose={close} />
        </Dialog>
    )
}

type IframeContentProps = {
    handleClose: () => void
    variant: Variant
}

const IframeContent: React.FC<IframeContentProps> = ({ handleClose, variant }) => {
    const { id } = useParams<{ id: string }>()
    const [status, setStatus] = useState<'loading' | 'ready'>('loading')

    useEffect(() => {
        const onMessage = (event: MessageEvent) => {
            if (event.origin !== runtimeEnv.REACT_APP_QUIZ_URL) return

            if (event.data.type === 'READY') {
                setStatus('ready')
            }

            if (event.data.type === 'CLOSE') {
                handleClose()
            }
        }

        window.addEventListener('message', onMessage)

        return () => {
            window.removeEventListener('message', onMessage)
        }
    }, [handleClose])

    const iframeUrl =
        variant === 'classroom'
            ? `${runtimeEnv.REACT_APP_QUIZ_URL}/${id}?mode=iframe&access_token=${api.accessToken}`
            : `${runtimeEnv.REACT_APP_QUIZ_URL}/room-for/${id}?mode=iframe&access_token=${api.accessToken}`

    return (
        <Root>
            {status === 'loading' && (
                <LoaderWrap>
                    <Loader />
                </LoaderWrap>
            )}
            <StyledIframe
                data-testid="quiz-iframe"
                isReady={status === 'ready'}
                src={iframeUrl}
                title="Quiz"
                frameBorder={0}
            />
        </Root>
    )
}

export const SelfHostQuizIframe = () => {
    return <QuizIframe variant="self-host" />
}

export const ClassroomQuizIframe = () => {
    return <QuizIframe variant="classroom" />
}

const StyledPaper = styled(Paper)`
    height: 100%;
    background-color: #0e375c;
`

const LoaderWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
`

const StyledIframe = styled.iframe<{ isReady: boolean }>`
    width: 100%;
    height: 100%;
    opacity: ${props => (!props.isReady ? 0 : undefined)};
`

const Root = styled.div`
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`
