import { ApiBase } from 'lib/api/ApiBase'
import { Survey } from '../types/SurveyTypes'
import { getSubjectNameKey } from '../../certificates/certificateHelpers'

export class SurveysApi extends ApiBase {
    getUserSurveys = async () => {
        const res = await this.client.get<Survey[]>('/accounts/my-surveys-v2')

        const surveys = res.data.map((survey: Survey) => {
            survey.subjectName = this.getSurveySubjectTranslation(survey.subject_key)
            return survey
        })
        return surveys
    }

    getSurveySubjectTranslation = (subjectKey: string = '') => {
        const key = getSubjectNameKey(subjectKey);
        return key ? `certificate::${key}::name` : ''
    }
}
