import { ApiBase } from '../api/ApiBase'
import { ApiTypes } from '../api/types'

export class LanguagesApi extends ApiBase {
    getLanguages = (publishedOnly: boolean) => {
        const params: Record<any, any> = {
            sort: 'displayName',
        }
        if (publishedOnly) {
            params.published = true
        }
        return this.client.get<ApiTypes['language'][]>('/languages', {
            params,
        })
    }

    getLocales = async (enabled: boolean) => {
        const params: Record<any, any> = {
            sort: 'name',
        }
        if (enabled) {
            params.enabled = true
        }

        return (
            await this.client.get<Locales[]>('/locales', {
                params,
            })
        ).data
    }

    getSchoolCountryCodes = async () => {
        return (
            await this.client.get<string[]>('/locales/school-countrycodes')
        ).data
    }
}

interface Locales {
    code: string
    enabled: boolean
    name: string
}
