export const SET_CURRENCY = 'SERVER/SET_CURRENCY'
export const SET_CURRENCY_REFILL = 'SERVER/SET_CURRENCY_REFILL'

export const CHANGE_CAPACITY = 'SERVER/CHANGE_CAPACITY'
export const ADD_TO_INVENTORY = 'SERVER/ADD_TO_INVENTORY'
export const CHANGE_STATISTIC = 'SERVER/CHANGE_STATISTIC'

export const CREATE_SUBSCRIPTION = 'CLIENT/CREATE_SUBSCRIPTION'

export const CATALOG = 'SERVER/CATALOG'
export const REQUEST_INVENTORY = 'CLIENT/REQUEST_INVENTORY'
export const INVENTORY = 'SERVER/INVENTORY'
export const REQUEST_LEADERBOARD = 'CLIENT/REQUEST_LEADERBOARD'
export const LEADERBOARD = 'SERVER/LEADERBOARD'
export const LEADERBOARD_FETCHED = 'SERVER/LEADERBOARD:FETCHED'
export const LEADERBOARD_RANK = 'SERVER/LEADERBOARD_RANK'
export const LEADERBOARD_VERSION = 'SERVER/LEADERBOARD_VERSION'
export const DISABLE_ALL_LEADERBOARDS_FOR_PROJECT = 'DISABLE_ALL_LEADERBOARDS_FOR_PROJECT'
export const REQUEST_STATISTIC = 'CLIENT/REQUEST_STATISTIC'
export const REQUEST_STATISTICS = 'CLIENT/REQUEST_STATISTICS'
export const STATISTIC = 'SERVER/STATISTIC'
export const REQUEST_SCORE_LIST = 'CLIENT/UNIVERSE/REQUEST_SCORE_LIST'
export const SCORE_LIST = 'SERVER/UNIVERSE/SCORE_LIST'
export const SET_LEADERBOARD_LENGTH = 'CLIENT/SET_LEADERBOARD_LENGTH'

export const REPORT_PROJECT = 'CLIENT/UNIVERSE/REPORT_PROJECT'

export const CAST_VOTE_REQUEST = 'CLIENT/CAST_VOTE_REQUEST'
export const REMOVE_FROM_INVENTORY = 'SERVER/REMOVE_FROM_INVENTORY'

export const PUSH_NOTIFICATION = 'SERVER/PUSH_NOTIFICATION'
export const REQUEST_NOTIFICATIONS = 'CLIENT/REQUEST_NOTIFICATIONS'
export const NOTIFICATIONS = 'SERVER/NOTIFICATIONS'
export const READ_NOTIFICATIONS = 'CLIENT/READ_NOTIFICATIONS'
export const READ_NOTIFICATION = 'CLIENT/READ_NOTIFICATION'
export const MARK_NOTIFICATION_AS_DISPLAYED = 'CLIENT/MARK_NOTIFICATION_AS_DISPLAYED'
export const MARK_ALL_NOTIFICATION_AS_DISPLAYED = 'CLIENT/MARK_ALL_NOTIFICATION_AS_DISPLAYED'
export const REQUEST_WELCOME = 'CLIENT/UNIVERSE/REQUEST_WELCOME'

export const FOLLOW_USER = 'CLIENT/UNIVERSE/FOLLOW_USER'
export const UNFOLLOW_USER = 'CLIENT/UNIVERSE/UNFOLLOW_USER'

export const COMPLETE_PROFILE = 'CLIENT/UNIVERSE/COMPLETE_PROFILE'
export const CHANGED_PROFILE_PICTURE = 'CLIENT/UNIVERSE/CHANGED_PROFILE_PICTURE'

export const ROOM_CREATED = 'SERVER/SCOOLCODE/ROOM_CREATED'
export const REQUEST_ROOMS = 'CLIENT/SCOOLCODE/REQUEST_ROOMS'
export const ROOMS = 'SERVER/SCOOLCODE/ROOMS'
export const SHARED_STATE = 'SERVER/SCOOLCODE/SHARED_STATE'

export const GRANTS_CHANGED = 'SERVER/GRANTS_CHANGED'
export const USER_CHANGED = 'SERVER/UNIVERSE/USER_CHANGED'
export const PROJECT_SHARE_APPROVED = 'SERVER/UNIVERSE/PROJECT_SHARE_APPROVED'

export const INCREMENT_ACTIVITY_SCORE = 'CLIENT/UNIVERSE/INCREMENT_ACTIVITY_SCORE'
