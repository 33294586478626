import { OnboardingAchievementDefinition } from 'features/events/pages/onboarding/data/onboardingData'
import produce from 'immer'
import { NOTIFICATIONS, PUSH_NOTIFICATION } from 'lib/liveops/constants'
import { Notification } from 'lib/liveops/models/notification'
import { AnyAction, Reducer } from 'redux'
import { dialogFromNotification } from '..'
import { PAUSE_QUEUE, PUSH_QUEUE, RESUME_QUEUE, SHIFT_QUEUE } from './dialogQueueActionyTypes'

export interface OnboardingAchievementUnlockedDialogData {
    type: 'onboarding-achievement-unlocked'
    notificationId: string
    achievement: OnboardingAchievementDefinition
}

export interface OnboardingPointsReachedDialogData {
    type: 'onboarding-points-reached'
    notificationId: string
}

export interface GeneralDialogData {
    type: 'general'
    notificationId: string
    image?: string
    title: string
    body: string
    disablePopup?: boolean
    disableConfetti?: boolean
    bodyInterpolation?: Record<string, any>
    button?: {
        label: string
        to: string
    }
}

// export interface AttendedOpenDayDialogData {
//     type: 'attended-open-day'
//     notificationId: string
// }

export type QueuedDialogData =
    | GeneralDialogData
    | OnboardingAchievementUnlockedDialogData
    | OnboardingPointsReachedDialogData
// | AttendedOpenDayDialogData

export interface DialogQueueState {
    queue: QueuedDialogData[]
    isPaused: boolean
}

export const dialogQueueReducer: Reducer<DialogQueueState, AnyAction> = (
    state = { queue: [], isPaused: false },
    action,
) => {
    switch (action.type) {
        case NOTIFICATIONS:
            return produce(state, draft => {
                const notifications: Notification[] = action.payload.notifications

                notifications
                    .filter(notification => !notification.displayed)
                    .forEach(notification => {
                        const dialog = dialogFromNotification(notification)
                        if (dialog) {
                            draft.queue.push(dialog)
                        }
                    })
            })

        case PUSH_NOTIFICATION:
            return produce(state, draft => {
                const dialog = dialogFromNotification(action.payload.notification)
                if (dialog) {
                    draft.queue.push(dialog)
                }
            })

        case SHIFT_QUEUE:
            return produce(state, draft => {
                draft.queue.shift()
            })

        case PUSH_QUEUE:
            return produce(state, draft => {
                draft.queue.push(action.payload.dialogData)
            })

        case PAUSE_QUEUE:
            return produce(state, draft => {
                draft.isPaused = true
            })

        case RESUME_QUEUE:
            return produce(state, draft => {
                draft.isPaused = false
            })

        default:
            return state
    }
}
