import dayjs from 'dayjs'
import { ApiBase } from 'lib/api/ApiBase'
import { Meeting } from '../types/meetingTypes'

export class MeetingsApi extends ApiBase {
    getTodaysMeetings = async () => {
        const res = await this.client.get<Meeting[]>('accounts/me/meetings')
        const today = new Date().getDay()

        return res.data
            .filter(meeting => (meeting.daysOfWeek || []).includes(today))
            .sort((a, b) => (dayjs.utc(a.time).isBefore(b.time!) ? -1 : 1))
    }
}
