import React, { useRef, useState } from 'react'
import { Flex, Inline, Stack } from 'components/layout'
import { Button } from 'components/Button'
import { Option, Select, TextField } from 'components/form'
import { useHistory } from 'react-router-dom'
import { useProjectActions } from 'features/projects/useProjectActions'
import { Text } from 'components/Text'
import { IconButton } from 'components/IconButton'
import { BackIcon, ForwardIcon } from 'components/icons'
import { Markdown } from 'components/Markdown'
import { Loader } from 'components/Loader'
import { Controller, useForm, UseFormMethods } from 'react-hook-form'
import { useNotifications } from 'features/notifications'
import { Project } from '..'
import { ProjectModalContent } from '../modal/ProjectModalContent'
import { useProjectModalContext } from '../modal/projectModalContext'
import { ProjectModalFooter } from '../modal/ProjectModalFooter'
import { ProjectModalHeader } from '../modal/ProjectModalHeader'
import { ProjectThumbnailInput } from '../ProjectThumbnailInput'
import { useLanguagesQuery } from 'lib/i18n'
import { canUserCreateShareableProject, useUserState } from 'contexts/UserContext'
import { ApiTypes } from 'lib/api'
import { useTitle } from 'hooks/useTitle'
import {
    createProjectThumbnailUrl,
    doesProjectHaveNewVersion,
    getRemainingShareSlotCount,
    PROJECT_DETAILS_MAX_LENGTH,
    PROJECT_TITLE_MAX_LENGTH,
} from '../projectHelpers'
import { useCommunityProjectDetailsQuery, useMyPendingProjectsQuery, useSharedProjectsQuery } from '../projectQueries'
import { ErrorMessage } from 'components/ErrorMessage'
import { ProjectModalError } from '../modal/ProjectModalError'
import { NBSP } from 'utils/styleUtils'
import { useTranslate } from 'lib/i18n/useTranslate'
import { useHasGrant } from 'lib/grants'
import { useIncrementActivityScore } from '../../../hooks/useIncrementActivityScore'
import { useSelector } from 'lib/store'
import { isMiniQuizLongEnoughToShare } from 'features/mini-quizzes/helpers/miniQuizHelpers'

export const ProjectShare: React.FC = () => {
    const user = useUserState()
    const { project, query, error, id } = useProjectModalContext()
    const { data: languages, status: languagesStatus } = useLanguagesQuery()
    const { data: pendingProjects, status: pendingProjectsStatus } = useMyPendingProjectsQuery()
    const { data: sharedProjects, status: sharedProjectsStatus } = useSharedProjectsQuery(user.account.id)
    const { status: detailsStatus } = useCommunityProjectDetailsQuery(id, 'normal', true)
    const projects = useSelector(state => state.projects.all)
    const t = useTranslate()

    useTitle(t('projects::shareProject'))

    if (
        error ||
        languagesStatus === 'error' ||
        pendingProjectsStatus === 'error' ||
        sharedProjectsStatus === 'error' ||
        detailsStatus === 'error'
    ) {
        return <ProjectModalError error={error || 'GENERAL'} />
    }

    if (
        !project ||
        query.status === 'loading' ||
        detailsStatus === 'loading' ||
        !languages ||
        !sharedProjects ||
        !pendingProjects
    ) {
        return <ProjectShareLoader />
    }

    const isProjectUpdate = doesProjectHaveNewVersion(project)

    const remainingShareSlotCount = getRemainingShareSlotCount(
        user,
        sharedProjects.data.map(project => projects[project.id]),
        pendingProjects.map(id => projects[id]),
    )

    if (project.metadata.isMiniQuiz) {
        return (
            <MiniQuizShareValidator
                project={project}
                isProjectUpdate={isProjectUpdate}
                remainingShareSlotCount={remainingShareSlotCount.miniQuiz}
            >
                <ProjectShareForm
                    variant="mini-quiz"
                    project={project}
                    languages={languages.data}
                    isProjectUpdate={isProjectUpdate}
                />
            </MiniQuizShareValidator>
        )
    }

    return (
        <ProjectShareValidator
            project={project}
            isProjectUpdate={isProjectUpdate}
            remainingShareSlotCount={remainingShareSlotCount.normal}
        >
            <ProjectShareForm
                variant="normal"
                project={project}
                languages={languages.data}
                isProjectUpdate={isProjectUpdate}
            />
        </ProjectShareValidator>
    )
}

interface ValidatorProps {
    isProjectUpdate: boolean
    remainingShareSlotCount: number
    project: Project
}

const ProjectShareValidator: React.FC<ValidatorProps> = ({ isProjectUpdate, remainingShareSlotCount, children }) => {
    const t = useTranslate()
    const user = useUserState()
    const canCreateShareableProject = canUserCreateShareableProject(user)

    if ((!canCreateShareableProject || remainingShareSlotCount === 0) && !isProjectUpdate) {
        return (
            <>
                <ProjectModalHeader>{NBSP}</ProjectModalHeader>
                <ProjectModalContent>
                    <ErrorMessage>{t('projects::noMoreShareSlots')}</ErrorMessage>
                </ProjectModalContent>
            </>
        )
    }

    return <>{children}</>
}

const MiniQuizShareValidator: React.FC<ValidatorProps> = ({
    isProjectUpdate,
    remainingShareSlotCount,
    children,
    project,
}) => {
    const t = useTranslate()

    if (!isMiniQuizLongEnoughToShare(project)) {
        return (
            <>
                <ProjectModalHeader>{NBSP}</ProjectModalHeader>
                <ProjectModalContent>
                    <ErrorMessage>{t('miniQuiz::errors::atLeastFiveQuestions')}</ErrorMessage>
                </ProjectModalContent>
            </>
        )
    }
    if (remainingShareSlotCount === 0 && !isProjectUpdate) {
        return (
            <>
                <ProjectModalHeader>{NBSP}</ProjectModalHeader>
                <ProjectModalContent>
                    <ErrorMessage>{t('projects::noMoreShareSlots')}</ErrorMessage>
                </ProjectModalContent>
            </>
        )
    }

    return <>{children}</>
}

type ProjectShareFormValues = {
    title: string
    details: string
    thumbnail: string
    lang: string
}

type Variant = 'normal' | 'mini-quiz'

interface ProjectShareFormProps {
    project: Project
    languages: ApiTypes['language'][]
    isProjectUpdate: boolean
    variant: Variant
}

const ProjectShareForm: React.FC<ProjectShareFormProps> = ({ project, languages, isProjectUpdate, variant }) => {
    const [state, setState] = useState<'terms' | 'edit'>('terms')
    const { i18n } = useUserState()
    const defaultLang = (() => {
        const lang = project.file.lang || i18n.language
        if (!languages.find(language => language.code === lang)) {
            return ''
        }
        return lang || ''
    })()

    const form = useForm<ProjectShareFormValues>({
        defaultValues: {
            title: project.file.title || project.file.name || '',
            details: project.metadata.details?.normal || '',
            thumbnail: project.file.hasUserThumbnail ? createProjectThumbnailUrl(project.file.id, 'normal') : '',
            lang: defaultLang,
        },
        shouldUnregister: false,
    })

    return (
        <>
            {state === 'terms' && (
                <ProjectShareTerms
                    variant={variant}
                    next={() => {
                        setState('edit')
                    }}
                    isProjectUpdate={isProjectUpdate}
                />
            )}
            {state === 'edit' && (
                <ProjectShareEdit
                    form={form}
                    variant={variant}
                    languages={languages}
                    back={() => {
                        setState('terms')
                    }}
                    isProjectUpdate={isProjectUpdate}
                />
            )}
        </>
    )
}

interface ProjectShareEditProps {
    back: () => void
    form: UseFormMethods<ProjectShareFormValues>
    languages: ApiTypes['language'][]
    isProjectUpdate: boolean
    variant: Variant
}

const ProjectShareEdit: React.FC<ProjectShareEditProps> = ({ back, form, languages, isProjectUpdate, variant }) => {
    const { iframeRef, id } = useProjectModalContext()
    const { shareProject } = useProjectActions()
    const t = useTranslate()
    const incrementActivityScore = useIncrementActivityScore()
    const [status, setStatus] = useState<'idle' | 'loading'>('idle')
    const history = useHistory()
    const { notify } = useNotifications()
    const thumbnailButtonRef = useRef<any>(null)
    const hasGrant = useHasGrant()
    const { register, errors, handleSubmit, control } = form

    const submit = handleSubmit(async ({ title, details, thumbnail, lang }) => {
        if (status === 'idle') {
            setStatus('loading')
            try {
                const canShareWithoutApprove = hasGrant('share-project-without-approval')
                await shareProject({
                    id,
                    title,
                    details,
                    thumbnail,
                    lang,
                    needsApprove: !canShareWithoutApprove,
                    isMiniQuiz: variant === 'mini-quiz',
                })
                if (isProjectUpdate) {
                    incrementActivityScore('community:owned-project:update')
                } else {
                    incrementActivityScore('community:owned-project:share')
                }
                history.push('/app/community/share-project')
                notify(t(canShareWithoutApprove ? 'projects::shareSuccessWithGrant' : 'projects::shareSuccess'), {
                    variant: 'success',
                })
            } catch (e) {
                notify(t('error::somethingWentWrong'), { variant: 'error' })
                setStatus('idle')
            }
        }
    })

    return (
        <>
            <ProjectModalHeader>
                <Inline display="flex" alignItems="center" spacing={2}>
                    <IconButton color="inherit" onClick={back} size="small">
                        <BackIcon />
                    </IconButton>
                    {isProjectUpdate ? <ProjectUpdateTitle /> : <ProjectShareTitle />}
                </Inline>
            </ProjectModalHeader>
            <ProjectModalContent>
                <form onSubmit={submit} id="share-form">
                    <Stack spacing={6}>
                        <TextField
                            errorText={errors.title?.message}
                            ref={register({
                                required: { value: true, message: t('projects::titleIsRequired') },
                                validate: value => {
                                    return value.trim() ? true : t('projects::titleIsRequired')
                                },
                                maxLength: {
                                    value: PROJECT_TITLE_MAX_LENGTH,
                                    message: t('general::maxLength', { value: PROJECT_TITLE_MAX_LENGTH }),
                                },
                            })}
                            inputProps={{ maxLength: PROJECT_TITLE_MAX_LENGTH }}
                            name="title"
                            id="title"
                            label={t('general::title')}
                        />
                        <TextField
                            errorText={errors.details?.message}
                            name="details"
                            helperText={variant === 'normal' ? t('projects::detailsHelper') : undefined}
                            multiline
                            rows={3}
                            ref={register({
                                maxLength: {
                                    value: PROJECT_DETAILS_MAX_LENGTH,
                                    message: t('general::maxLength', { value: PROJECT_DETAILS_MAX_LENGTH }),
                                },
                            })}
                            inputProps={{ maxLength: PROJECT_DETAILS_MAX_LENGTH }}
                            label={t('general::description')}
                        />
                        <Controller
                            name="lang"
                            control={control}
                            rules={{ required: { value: true, message: t('projects::languageIsRequired') } }}
                            as={
                                <Select errorText={errors.lang?.message} label={t('general::language')}>
                                    {languages.map(language => (
                                        <Option key={language.code} value={language.code}>
                                            {language.displayName}
                                        </Option>
                                    ))}
                                </Select>
                            }
                        />
                        {variant === 'normal' && (
                            <Controller
                                name="thumbnail"
                                control={control}
                                rules={{ required: { value: true, message: t('projects::thumbnailIsRequired') } }}
                                onFocus={() => {
                                    thumbnailButtonRef.current?.scrollIntoView()
                                }}
                                as={
                                    <ProjectThumbnailInput
                                        buttonRef={thumbnailButtonRef}
                                        embedIframeRef={iframeRef}
                                        helperText={t('projects::thumbnailHelperText')}
                                        errorText={errors.thumbnail?.message}
                                        buttonText={
                                            isProjectUpdate
                                                ? t('projects::updateThumbnail')
                                                : t('projects::takeThumbnail')
                                        }
                                    />
                                }
                            />
                        )}
                    </Stack>
                </form>
            </ProjectModalContent>
            <ProjectModalFooter>
                <Button isLoading={status === 'loading'} type="submit" form="share-form">
                    {t(isProjectUpdate ? 'projects::updateProject' : 'projects::shareProject')}
                </Button>
            </ProjectModalFooter>
        </>
    )
}

interface ProjectShareTermsProps {
    next: () => void
    isProjectUpdate: boolean
    variant: Variant
}

const ProjectShareTerms: React.FC<ProjectShareTermsProps> = ({ next, isProjectUpdate, variant }) => {
    const t = useTranslate()

    const variantToTermsKey: Record<Variant, string> = {
        normal: 'projects::sharingPrinciples',
        'mini-quiz': 'miniQuiz::sharingPrinciples',
    }

    return (
        <>
            <ProjectModalHeader>{isProjectUpdate ? <ProjectUpdateTitle /> : <ProjectShareTitle />}</ProjectModalHeader>
            <ProjectModalContent>
                <Stack>
                    <Markdown>{t(variantToTermsKey[variant])}</Markdown>
                </Stack>
            </ProjectModalContent>
            <ProjectModalFooter>
                <Button endIcon={<ForwardIcon />} onClick={next}>
                    {t('general::nextStep')}
                </Button>
            </ProjectModalFooter>
        </>
    )
}

const ProjectShareTitle = () => {
    const t = useTranslate()

    return (
        <Text variant="h3" component="h2">
            {t('projects::shareProject')}
        </Text>
    )
}

const ProjectUpdateTitle = () => {
    const t = useTranslate()

    return (
        <Text variant="h3" component="h2">
            {t('projects::updateProject')}
        </Text>
    )
}

const ProjectShareLoader = () => (
    <>
        <ProjectModalHeader>
            <ProjectShareTitle />
        </ProjectModalHeader>
        <ProjectModalContent>
            <Flex justify="center">
                <Loader />
            </Flex>
        </ProjectModalContent>
    </>
)
