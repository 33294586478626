import { Text } from 'components/Text'
import { useDateFormat } from 'hooks/useDateFormat'
import { readNotification } from 'lib/liveops'
import { Notification } from 'lib/liveops/models/notification'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import styled from 'styled-components/macro'
import { actionablePaperStyles, resetBtnStyle } from 'utils/styleUtils'
import { useNotificationToNotificationView } from './useNotificationToNotificationView'
import { useIncrementActivityScore } from 'hooks/useIncrementActivityScore'
import { pushQueue } from 'features/dialog-queue'

interface NotificationBlockProps {
    data: Notification
    isRead: boolean
    closePanel: () => void
}

export const NotificationBlock: React.FC<NotificationBlockProps> = ({ data, isRead, closePanel }) => {
    const notificationView = useNotificationToNotificationView(data)
    const { formatDate } = useDateFormat('numeric')
    const dispatch = useDispatch()
    const history = useHistory()
    const incrementActivityScore = useIncrementActivityScore()

    if (!notificationView) return null

    const isButton = !!notificationView.cta

    const handleClick = () => {
        incrementActivityScore('notifications:click')
        dispatch(readNotification(data.id))
        if (notificationView.cta) {
            switch (notificationView.cta.type) {
                case 'internal-link':
                    history.push(notificationView.cta.link)
                    break
                case 'external-link':
                    window.open(notificationView.cta.link, '_blank')
                    break
                case 'dialog':
                    dispatch(pushQueue(notificationView.cta.data))
                    break
                case 'custom':
                    notificationView.cta.onClick()
            }
        }
        closePanel()
    }

    return (
        <StyledNotiContent isRead={isRead} onClick={handleClick} as={isButton ? 'button' : 'div'} isBtn={isButton}>
            {notificationView.img}
            <StyledContainer isRead={isRead}>
                <StyledTitle variant="subtitle2">
                    <strong>{notificationView.title}</strong>
                </StyledTitle>
                <Text variant="body2">{notificationView.body}</Text>
                <StyledDate variant="caption">{formatDate(new Date(data.createdAt))}</StyledDate>
            </StyledContainer>
        </StyledNotiContent>
    )
}

const StyledContainer = styled.div<{ isRead: boolean }>`
    display: flex;
    flex-direction: column;
    opacity: ${props => (props.isRead ? '0.6' : undefined)};
`

const StyledTitle = styled(Text)`
    line-height: 1.2;
`

const StyledDate = styled(Text)`
    opacity: 0.8;
`

const StyledNotiContent = styled.div<{ isRead: boolean; isBtn: boolean }>`
    ${resetBtnStyle}
    ${actionablePaperStyles}

    width: 100%;
    padding: ${props => props.theme.spacing(3, 4)};
    display: grid;
    grid-template-columns: 4rem 1fr;
    grid-gap: ${props => props.theme.spacing(3)};
    align-items: center;
    cursor: ${props => (props.isBtn ? 'pointer' : 'default')};
    border-left: 5px solid ${props => (props.isRead ? 'transparent' : props.theme.palette.primary.main)};
`
