import { useUserState } from 'contexts/UserContext'
import { useHasGrant } from 'lib/grants'

interface LocaleString {
    key?: string
    values?: {
        locale?: string
        value?: string
    }[]
}

export function useLocalisedQuizValue() {
    const { i18n } = useUserState()

    const translateQuizLocaleString = (key?: LocaleString) => {
        if (key === undefined) {
            return ''
        }

        const localisedName =
            key.values?.find(value => value.locale === i18n.locale) ||
            key.values?.find(value => value.locale === 'en-US')

        return localisedName?.value || ''
    }

    return {
        translateQuizLocaleString,
    }
}

export function useHasQuizAccess() {
    const hasGrant = useHasGrant()
    return hasGrant({ product: 'edup.quiz', feature: 'login' })
}
