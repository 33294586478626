import React from 'react'
import { ButtonProps as MuiButtonProps, Button as MuiButton, ButtonTypeMap } from '@material-ui/core'
import styled, { css } from 'styled-components/macro'
import { Loader } from './Loader'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'

type ExtraProps = {
    isLoading?: boolean
}

export type ButtonProps = MuiButtonProps<any, ExtraProps>

export const Button = React.forwardRef(
    ({ isLoading, children, disabled, variant = 'contained', color = 'primary', ...rest }: ButtonProps, ref) => {
        const Root = variantMap[variant]

        return (
            <Root
                ref={ref}
                disableElevation
                disabled={isLoading ? true : disabled}
                variant={variant}
                color={color}
                {...rest}
            >
                <ChildrenWrap isLoading={isLoading}>{children}</ChildrenWrap>
                {isLoading && <StyledLoader size="1em" />}
            </Root>
        )
    },
) as OverridableComponent<ButtonTypeMap<ExtraProps>>

const VERTICAL_SPACING = 2
const HORIZONTAL_SPACING = 4
const buttonStyles = css`
    transition: all ${props => props.theme.transitions.duration.short}ms;
    text-transform: none;
    font-weight: 600;
    padding: ${props => props.theme.spacing(VERTICAL_SPACING, HORIZONTAL_SPACING)};
    &:focus-visible {
        outline: solid 1px ${props => props.theme.palette.primary.main};
    }
`

const TextButton = styled(MuiButton)`
    ${buttonStyles}
    padding: 0;
    background: none;
    border-radius: 0;
    border-bottom: solid 1px transparent;
    &:hover,
    &:focus-visible {
        background: none;
        filter: brightness(0.8);
    }
    ${props => props.theme.extras?.textButton?.(props.color)}
`

const OutlinedButton = styled(MuiButton)`
    ${buttonStyles}
    /* border correction */
    padding: calc(${props => props.theme.spacing(VERTICAL_SPACING)} - 1px) calc(${props =>
        props.theme.spacing(HORIZONTAL_SPACING)} - 1px)
`

const containedStyles: Record<NonNullable<ButtonProps['color']>, ReturnType<typeof css> | undefined> = {
    default: css`
        &:hover,
        &:focus-visible {
            filter: brightness(1.1);
        }
    `,
    inherit: undefined,
    primary: css`
        background: linear-gradient(
            to right bottom,
            ${props => props.theme.palette.primary.main},
            ${props => props.theme.palette.primary.dark}
        );
        &.Mui-disabled {
            background: ${props => props.theme.palette.grey[300]};
        }
        &:hover,
        &:focus-visible {
            filter: brightness(1.1);
        }
    `,
    secondary: undefined,
}

const ContainedButton = styled(MuiButton)`
    ${buttonStyles}
    ${props => containedStyles[props.color!]}
    .MuiButton-iconSizeMedium > *:first-child {
        font-size: 1.5em;
    }
    ${props => props.theme.extras?.containedButton?.(props.color)}
`

const variantMap: Record<
    NonNullable<ButtonProps['variant']>,
    typeof TextButton | typeof ContainedButton | typeof OutlinedButton
> = {
    text: TextButton,
    contained: ContainedButton,
    outlined: OutlinedButton,
}

const ChildrenWrap = styled.span<{ isLoading?: boolean }>`
    opacity: ${props => (props.isLoading ? 0 : 1)};
`

const StyledLoader = styled(Loader)`
    position: absolute;
`

export const DeleteButton = styled(Button)`
    color: ${props => props.theme.palette.error.contrastText};
    background: ${props => props.theme.palette.error.main};
    &:hover,
    &:focus-visible {
        color: ${props => props.theme.palette.error.contrastText};
        background: ${props => props.theme.palette.error.dark};
        filter: none;
    }
`
