import { Subject } from './certificateTypes'
import {
    PdfTemplateBlox,
    PdfTemplateMix,
    PdfTemplateA0,
    PdfTemplateGodot,
    PdfTemplateUnity,
    PdfTemplateRobot,
    PdfTemplateVideo,
    PdfTemplateDesign,
    PdfTemplateMinecraft,
    PdfTemplateRpg,
    PdfTemplateKodu,
    PdfTemplateAi,
    PdfTemplateBizniscool,
    PdfTemplateRoblox,
    PdfTemplateOpenDay,
    PdfTemplateDigitalMission,
    PdfTemplateWorkshop3d,
    PdfTemplateWorkshopApp,
    PdfTemplateWorkshopIllustration,
    PdfTemplateWorkshopMinecraft,
    PdfTemplatePython,
    PdfTemplateDigitalDiscovery,
    PdfTemplateDigitalMission2021,
    PdfTemplateDigitalMission2022,
    PdfTemplateDigitalMission2023,
    PdfTemplateWorkshopImagicharm,
    PdfTemplateScoolerMission,
    PdfTemplateAiPython,
    PdfTemplateAiBasic,
    PdfTemplateCampChallengeDay,
    PdfTemplateRobotPrime,
    PdfTemplateDevMaster,
    PdfTemplateExplorerJuniorSpike,
    PdfTemplateExplorerJuniorWedo,
    PdfTemplateExplorerJuniorBeebot,
    PdfTemplateExplorerCreative,
    PdfTemplateExplorerGamer,
    PdfTemplateApp,
    PdfTemplateIllustration,
    PdfTemplate3d,
    PdfTemplateImagicharm,
} from './PdfTemplates'

const thumbnails = {
    blox: '/certificate/thumbnails/blox.png',
    mix: '/certificate/thumbnails/mix.png',
    robot: '/certificate/thumbnails/lego.png',
    digitalIntro: '/certificate/thumbnails/a0-intro.png',
    video: '/certificate/thumbnails/video.png',
    minecraft: '/certificate/thumbnails/minecraft.png',
    minecraftWorkshop: '/certificate/thumbnails/minecraft-workshop.png',
    digitalMission: '/certificate/thumbnails/dm.png',
    illustration: '/certificate/thumbnails/illustration.png',
    illustrationWorkshop: '/certificate/thumbnails/illustration-workshop.png',
    rpg: '/certificate/thumbnails/rpg.png',
    ai: '/certificate/thumbnails/ai.png',
    roblox: '/certificate/thumbnails/roblox.png',
    bizniscool: '/certificate/thumbnails/bizniscool.png',
    app: '/certificate/thumbnails/app.png',
    threeD: '/certificate/thumbnails/three-d.png',
    openDay: '/certificate/thumbnails/open-day.png',
    digitalDiscovery: '/certificate/thumbnails/digital-discovery.png',
    mobile: '/certificate/thumbnails/mobile.png',
    digitalMission2021: '/certificate/thumbnails/dm2021.png',
    digitalMission2022: '/certificate/thumbnails/dm2022.png',
    digitalMission2023: '/certificate/thumbnails/dm2023.png',
    imagicharm: '/certificate/thumbnails/imagicharm.png',
    scoolerMission: '/certificate/thumbnails/scoolerMission.png',
    aiPython: '/certificate/thumbnails/aiPython.png',
    aiBasic: '/certificate/thumbnails/aiBasic.png',
    explorerJunior: '/certificate/thumbnails/explorer-junior.png',
    explorerCreative: '/certificate/thumbnails/explorer-creative.png',
    explorerGamer: '/certificate/thumbnails/explorer-gamer.png',
    genAI: '/certificate/thumbnails/genaiworkshop.png',
}

export const allSubject: Subject[] = [
    {
        key: 'prog.bb.easy.1',
        template: PdfTemplateBlox,
        thumbnail: thumbnails.blox,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'prog.bb.normal.1',
        template: PdfTemplateBlox,
        thumbnail: thumbnails.blox,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'prog.bb.fast',
        template: PdfTemplateBlox,
        thumbnail: thumbnails.blox,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'prog.bb.fast',
        template: PdfTemplateBlox,
        thumbnail: thumbnails.blox,
        type: 'camp',
        blurbType: 'camp',
    },
    {
        key: 'prog.bb.easy.2',
        template: PdfTemplateBlox,
        thumbnail: thumbnails.blox,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'prog.bb.normal.2',
        template: PdfTemplateBlox,
        thumbnail: thumbnails.blox,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'prog.bb.easy.3',
        template: PdfTemplateBlox,
        thumbnail: thumbnails.blox,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'prog.ba.normal.1',
        template: PdfTemplateBlox,
        thumbnail: thumbnails.blox,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'prog.ba.normal.2',
        template: PdfTemplateBlox,
        thumbnail: thumbnails.blox,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'prog.ba.fast',
        template: PdfTemplateBlox,
        thumbnail: thumbnails.blox,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'prog.bm',
        template: PdfTemplateBlox,
        thumbnail: thumbnails.blox,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'prog.mb',
        template: PdfTemplateMix,
        thumbnail: thumbnails.mix,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'prog.mb.python',
        nameKey: 'prog.mb',
        template: PdfTemplateMix,
        thumbnail: thumbnails.mix,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'prog.ma',
        template: PdfTemplateMix,
        thumbnail: thumbnails.mix,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'prog.ma.python',
        template: PdfTemplateMix,
        thumbnail: thumbnails.mix,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'prog.mm',
        template: PdfTemplateMix,
        thumbnail: thumbnails.mix,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'prog.mm.py',
        template: PdfTemplateMix,
        thumbnail: thumbnails.mix,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'prog.cg',
        template: PdfTemplateMix,
        thumbnail: thumbnails.mix,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'prog.cg.py',
        template: PdfTemplateMix,
        thumbnail: thumbnails.mix,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'prog.gd.blox',
        template: PdfTemplateBlox,
        thumbnail: thumbnails.blox,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'prog.bi.normal',
        template: PdfTemplateBlox,
        thumbnail: thumbnails.mix,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'prog.bi.fast',
        template: PdfTemplateMix,
        thumbnail: thumbnails.mix,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'prog.bi.fast',
        template: PdfTemplateMix,
        thumbnail: thumbnails.mix,
        type: 'camp',
        blurbType: 'camp',
    },
    {
        key: 'prog.unity.basic',
        template: PdfTemplateUnity,
        thumbnail: thumbnails.mix,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'prog.unity.advanced',
        template: PdfTemplateUnity,
        thumbnail: thumbnails.mix,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'prog.unity.master',
        template: PdfTemplateUnity,
        thumbnail: thumbnails.mix,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'prog.godot.basic',
        template: PdfTemplateGodot,
        thumbnail: thumbnails.mix,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'prog.godot.advanced',
        template: PdfTemplateGodot,
        thumbnail: thumbnails.mix,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'prog.jr.kodu.basic',
        template: PdfTemplateKodu,
        thumbnail: thumbnails.digitalIntro,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'prog.jr.intro',
        template: PdfTemplateA0,
        thumbnail: thumbnails.digitalIntro,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'minecraft.codia.1',
        template: PdfTemplateMinecraft,
        thumbnail: thumbnails.minecraft,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'minecraft.codia.2',
        template: PdfTemplateMinecraft,
        thumbnail: thumbnails.minecraft,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'robot.lego.ev3.1',
        template: PdfTemplateRobot,
        thumbnail: thumbnails.robot,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'robot.lego.ev3.2',
        template: PdfTemplateRobot,
        thumbnail: thumbnails.robot,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'robot.mbot.course',
        template: PdfTemplateRobot,
        thumbnail: thumbnails.robot,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'digital.video',
        template: PdfTemplateVideo,
        thumbnail: thumbnails.video,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'robot.mbot.camp',
        template: PdfTemplateRobot,
        thumbnail: thumbnails.robot,
        type: 'camp',
        blurbType: 'camp',
    },
    {
        key: 'legacy.robot.lego.wedo.1',
        template: PdfTemplateRobot,
        thumbnail: thumbnails.robot,
        type: 'camp',
        blurbType: 'camp',
    },
    {
        key: 'robot.lego.ev3.camp',
        template: PdfTemplateRobot,
        thumbnail: thumbnails.robot,
        type: 'camp',
        blurbType: 'camp',
    },
    {
        key: 'minecraft.redstone',
        template: PdfTemplateMinecraft,
        thumbnail: thumbnails.minecraft,
        type: 'camp',
        blurbType: 'camp',
    },
    {
        key: 'robot.cozmo',
        template: PdfTemplateRobot,
        thumbnail: thumbnails.robot,
        type: 'camp',
        blurbType: 'camp',
    },
    {
        key: 'minecraft.design',
        template: PdfTemplateMinecraft,
        thumbnail: thumbnails.minecraft,
        type: 'camp',
        blurbType: 'camp',
    },
    {
        key: 'digital.rpg',
        template: PdfTemplateRpg,
        thumbnail: thumbnails.rpg,
        type: 'camp',
        blurbType: 'camp',
    },
    {
        key: 'prog.android',
        template: PdfTemplateBlox,
        thumbnail: thumbnails.blox,
        type: 'camp',
        blurbType: 'camp',
    },
    {
        key: 'robot.lego.wedo.2',
        template: PdfTemplateRobot,
        thumbnail: thumbnails.robot,
        type: 'camp',
        blurbType: 'camp',
    },
    {
        key: 'robot.lego.wedo.2.future',
        template: PdfTemplateRobot,
        thumbnail: thumbnails.robot,
        type: 'camp',
        blurbType: 'camp',
    },
    {
        key: 'robot.lego.boost',
        template: PdfTemplateRobot,
        thumbnail: thumbnails.robot,
        type: 'camp',
        blurbType: 'camp',
    },
    {
        key: 'digital.designer',
        template: PdfTemplateDesign,
        thumbnail: thumbnails.illustration,
        type: 'camp',
        blurbType: 'camp',
    },
    {
        key: 'digital.designer',
        template: PdfTemplateDesign,
        thumbnail: thumbnails.illustration,
        type: 'workshop',
        blurbType: 'workshop',
    },
    {
        key: 'digital.roblox-design',
        template: PdfTemplateRoblox,
        thumbnail: thumbnails.roblox,
        type: 'camp',
        blurbType: 'camp',
    },
    {
        key: 'biz.choc',
        template: PdfTemplateBizniscool,
        thumbnail: thumbnails.bizniscool,
        type: 'camp',
        blurbType: 'camp',
    },
    {
        key: 'biz.toys',
        template: PdfTemplateBizniscool,
        thumbnail: thumbnails.bizniscool,
        type: 'camp',
        blurbType: 'camp',
    },
    {
        key: 'biz.toys',
        template: PdfTemplateBizniscool,
        thumbnail: thumbnails.bizniscool,
        type: 'workshop',
        blurbType: 'workshop',
    },
    {
        key: 'biz.vlog',
        template: PdfTemplateBizniscool,
        thumbnail: thumbnails.bizniscool,
        type: 'camp',
        blurbType: 'camp',
    },
    {
        key: 'biz.vlog',
        template: PdfTemplateBizniscool,
        thumbnail: thumbnails.bizniscool,
        type: 'workshop',
        blurbType: 'workshop',
    },
    {
        key: 'minecraft.codia.camp',
        template: PdfTemplateMinecraft,
        thumbnail: thumbnails.minecraft,
        type: 'camp',
        blurbType: 'camp',
    },
    {
        key: 'minecraft.pro',
        template: PdfTemplateMinecraft,
        thumbnail: thumbnails.minecraft,
        type: 'camp',
        blurbType: 'camp',
    },
    {
        key: 'prog.ai.camp',
        template: PdfTemplateAi,
        thumbnail: thumbnails.ai,
        type: 'camp',
        blurbType: 'camp',
    },
    {
        key: 'minecraft.mars',
        template: PdfTemplateMinecraft,
        thumbnail: thumbnails.minecraft,
        type: 'camp',
        blurbType: 'camp',
    },
    {
        key: 'digital.youtube',
        template: PdfTemplateVideo,
        thumbnail: thumbnails.video,
        type: 'camp',
        blurbType: 'camp',
    },
    {
        key: 'digital.introduction',
        template: PdfTemplateA0,
        thumbnail: thumbnails.digitalIntro,
        type: 'workshop',
        blurbType: 'workshop',
    },
    {
        key: 'digital.video.camp',
        template: PdfTemplateVideo,
        thumbnail: thumbnails.video,
        type: 'camp',
        blurbType: 'camp',
    },
    {
        key: 'digital.video.pro.camp',
        template: PdfTemplateVideo,
        thumbnail: thumbnails.video,
        type: 'camp',
        blurbType: 'camp',
    },
    {
        key: 'digital.discovery.1',
        template: PdfTemplateDigitalDiscovery,
        thumbnail: thumbnails.digitalDiscovery,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'prog.python.basic',
        template: PdfTemplatePython,
        thumbnail: thumbnails.mix,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'prog.jr.kodu.advanced',
        template: PdfTemplateKodu,
        thumbnail: thumbnails.digitalIntro,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'minecraft.modding',
        template: PdfTemplateWorkshopMinecraft,
        thumbnail: thumbnails.minecraftWorkshop,
        type: 'workshop',
        blurbType: 'workshop',
    },
    {
        key: 'minecraft.modding.camp',
        template: PdfTemplateMinecraft,
        thumbnail: thumbnails.minecraftWorkshop,
        type: 'camp',
        blurbType: 'camp',
    },
    {
        key: 'digital.tinkercad',
        template: PdfTemplateWorkshop3d,
        thumbnail: thumbnails.threeD,
        type: 'workshop',
        blurbType: 'workshop',
    },
    {
        key: 'digital.tinkercad.camp',
        template: PdfTemplate3d,
        thumbnail: thumbnails.threeD,
        type: 'camp',
        blurbType: 'camp',
    },
    {
        key: 'digital.illustration.normal',
        template: PdfTemplateWorkshopIllustration,
        thumbnail: thumbnails.illustrationWorkshop,
        type: 'workshop',
        blurbType: 'workshop',
    },
    {
        key: 'digital.illustration.normal.camp',
        template: PdfTemplateIllustration,
        thumbnail: thumbnails.illustrationWorkshop,
        type: 'camp',
        blurbType: 'camp',
    },
    {
        key: 'digital.illustration',
        template: PdfTemplateWorkshopIllustration,
        thumbnail: thumbnails.illustrationWorkshop,
        type: 'workshop',
        blurbType: 'workshop',
    },
    {
        key: 'prog.thunkable',
        template: PdfTemplateWorkshopApp,
        thumbnail: thumbnails.app,
        type: 'workshop',
        blurbType: 'workshop',
    },
    {
        key: 'prog.thunkable.camp',
        template: PdfTemplateApp,
        thumbnail: thumbnails.app,
        type: 'camp',
        blurbType: 'camp',
    },
    {
        key: 'digital.video.mobile',
        template: PdfTemplateVideo,
        thumbnail: thumbnails.video,
        type: 'camp',
        blurbType: 'camp',
    },
    {
        key: 'event.open-day.lower',
        template: PdfTemplateOpenDay,
        thumbnail: thumbnails.openDay,
        type: 'event',
        blurbType: 'openday',
    },
    {
        key: 'event.open-day.upper',
        template: PdfTemplateOpenDay,
        thumbnail: thumbnails.openDay,
        type: 'event',
        blurbType: 'openday',
    },
    {
        key: 'event.open-day.high',
        template: PdfTemplateOpenDay,
        thumbnail: thumbnails.openDay,
        type: 'event',
        blurbType: 'openday',
    },
    {
        key: 'event.open-day.digdisc',
        template: PdfTemplateOpenDay,
        thumbnail: thumbnails.openDay,
        type: 'event',
        blurbType: 'openday',
    },
    {
        key: 'event.open-day.advanced',
        template: PdfTemplateOpenDay,
        thumbnail: thumbnails.openDay,
        type: 'event',
        blurbType: 'openday',
    },
    {
        key: 'legacy.advertising',
        template: PdfTemplateOpenDay,
        thumbnail: thumbnails.openDay,
        type: 'event',
        blurbType: 'openday',
    },
    {
        key: 'prog.kodu.camp',
        template: PdfTemplateKodu,
        thumbnail: thumbnails.digitalIntro,
        type: 'camp',
        blurbType: 'camp',
    },
    {
        key: 'prog.jr.hackcamp',
        template: PdfTemplateBlox,
        thumbnail: thumbnails.blox,
        type: 'camp',
        blurbType: 'camp',
    },
    {
        key: 'prog.python.advanced',
        template: PdfTemplatePython,
        thumbnail: thumbnails.mix,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'digital.discovery.2',
        template: PdfTemplateDigitalDiscovery,
        thumbnail: thumbnails.digitalDiscovery,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'digital.discovery.basic.normal.1',
        template: PdfTemplateDigitalDiscovery,
        thumbnail: thumbnails.digitalDiscovery,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'digital.discovery.basic.normal.2',
        template: PdfTemplateDigitalDiscovery,
        thumbnail: thumbnails.digitalDiscovery,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'digital.discovery.advanced.normal.1',
        template: PdfTemplateDigitalDiscovery,
        thumbnail: thumbnails.digitalDiscovery,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'digital.discovery.advanced.normal.2',
        template: PdfTemplateDigitalDiscovery,
        thumbnail: thumbnails.digitalDiscovery,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'digital.mission.2020',
        template: PdfTemplateDigitalMission,
        thumbnail: thumbnails.digitalMission,
        type: 'event',
        blurbType: 'digitalmission',
    },
    {
        key: 'digital.mission.2021',
        template: PdfTemplateDigitalMission2021,
        thumbnail: thumbnails.digitalMission2021,
        type: 'event',
        blurbType: 'digitalmission',
    },
    {
        key: 'digital.mission.2022',
        template: PdfTemplateDigitalMission2022,
        thumbnail: thumbnails.digitalMission2022,
        type: 'event',
        blurbType: 'digitalmission',
    },
    {
        key: 'digital.mission.2023',
        template: PdfTemplateDigitalMission2023,
        thumbnail: thumbnails.digitalMission2023,
        type: 'event',
        blurbType: 'digitalmission',
    },
    {
        key: 'scooler.mission.2022',
        template: PdfTemplateScoolerMission,
        thumbnail: thumbnails.scoolerMission,
        type: 'event',
        blurbType: 'scoolerMission',
    },
    {
        key: 'scooler.mission.2023.1',
        nameKey: 'scooler.mission.2023.1',
        template: PdfTemplateScoolerMission,
        thumbnail: thumbnails.scoolerMission,
        type: 'event',
        blurbType: 'scoolerMission',
    },
    {
        key: 'scooler.mission.2023.2',
        nameKey: 'scooler.mission.2023.1',
        template: PdfTemplateScoolerMission,
        thumbnail: thumbnails.scoolerMission,
        type: 'event',
        blurbType: 'scoolerMission',
    },
    {
        key: 'scooler.mission.2024.1',
        nameKey: 'scooler.mission.2023.1',
        template: PdfTemplateScoolerMission,
        thumbnail: thumbnails.scoolerMission,
        type: 'event',
        blurbType: 'scoolerMission',
    },
    {
        key: 'scooler.mission.2024.2',
        nameKey: 'scooler.mission.2023.1',
        template: PdfTemplateScoolerMission,
        thumbnail: thumbnails.scoolerMission,
        type: 'event',
        blurbType: 'scoolerMission',
    },
    {
        key: 'scooler.mission.2025.1',
        nameKey: 'scooler.mission.2023.1',
        template: PdfTemplateScoolerMission,
        thumbnail: thumbnails.scoolerMission,
        type: 'event',
        blurbType: 'scoolerMission',
    },
    {
        key: 'scooler.mission.2025.2',
        nameKey: 'scooler.mission.2023.1',
        template: PdfTemplateScoolerMission,
        thumbnail: thumbnails.scoolerMission,
        type: 'event',
        blurbType: 'scoolerMission',
    },
    {
        key: 'scooler.mission.2026.1',
        nameKey: 'scooler.mission.2023.1',
        template: PdfTemplateScoolerMission,
        thumbnail: thumbnails.scoolerMission,
        type: 'event',
        blurbType: 'scoolerMission',
    },
    {
        key: 'scooler.mission.2026.2',
        nameKey: 'scooler.mission.2023.1',
        template: PdfTemplateScoolerMission,
        thumbnail: thumbnails.scoolerMission,
        type: 'event',
        blurbType: 'scoolerMission',
    },
    {
        key: 'scooler.mission.2027.1',
        nameKey: 'scooler.mission.2023.1',
        template: PdfTemplateScoolerMission,
        thumbnail: thumbnails.scoolerMission,
        type: 'event',
        blurbType: 'scoolerMission',
    },
    {
        key: 'scooler.mission.2027.2',
        nameKey: 'scooler.mission.2023.1',
        template: PdfTemplateScoolerMission,
        thumbnail: thumbnails.scoolerMission,
        type: 'event',
        blurbType: 'scoolerMission',
    },
    {
        key: 'scooler.mission.2028.1',
        nameKey: 'scooler.mission.2023.1',
        template: PdfTemplateScoolerMission,
        thumbnail: thumbnails.scoolerMission,
        type: 'event',
        blurbType: 'scoolerMission',
    },
    {
        key: 'scooler.mission.2028.2',
        nameKey: 'scooler.mission.2023.1',
        template: PdfTemplateScoolerMission,
        thumbnail: thumbnails.scoolerMission,
        type: 'event',
        blurbType: 'scoolerMission',
    },
    {
        key: 'scooler.mission.2029.1',
        nameKey: 'scooler.mission.2023.1',
        template: PdfTemplateScoolerMission,
        thumbnail: thumbnails.scoolerMission,
        type: 'event',
        blurbType: 'scoolerMission',
    },
    {
        key: 'scooler.mission.2029.2',
        nameKey: 'scooler.mission.2023.1',
        template: PdfTemplateScoolerMission,
        thumbnail: thumbnails.scoolerMission,
        type: 'event',
        blurbType: 'scoolerMission',
    },
    {
        key: 'scooler.mission.2030.1',
        nameKey: 'scooler.mission.2023.1',
        template: PdfTemplateScoolerMission,
        thumbnail: thumbnails.scoolerMission,
        type: 'event',
        blurbType: 'scoolerMission',
    },
    {
        key: 'scooler.mission.2030.2',
        nameKey: 'scooler.mission.2023.1',
        template: PdfTemplateScoolerMission,
        thumbnail: thumbnails.scoolerMission,
        type: 'event',
        blurbType: 'scoolerMission',
    },
    {
        key: 'prog.imagicharm',
        template: PdfTemplateWorkshopImagicharm,
        thumbnail: thumbnails.imagicharm,
        type: 'workshop',
        blurbType: 'workshop',
    },
    {
        key: 'prog.imagicharm.camp',
        template: PdfTemplateImagicharm,
        thumbnail: thumbnails.imagicharm,
        type: 'camp',
        blurbType: 'camp',
    },
    {
        key: 'prog.python.master',
        template: PdfTemplatePython,
        thumbnail: thumbnails.mix,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'robot.lego.spike.essential',
        template: PdfTemplateRobot,
        thumbnail: thumbnails.robot,
        type: 'camp',
        blurbType: 'camp',
    },
    {
        key: 'robot.lego.spike.prime',
        template: PdfTemplateRobotPrime,
        thumbnail: thumbnails.robot,
        type: 'camp',
        blurbType: 'camp',
    },
    {
        key: 'digital.illustration.junior',
        template: PdfTemplateWorkshopIllustration,
        thumbnail: thumbnails.illustrationWorkshop,
        type: 'workshop',
        blurbType: 'workshop',
    },
    {
        key: 'digital.illustration.junior.camp',
        template: PdfTemplateIllustration,
        thumbnail: thumbnails.illustrationWorkshop,
        type: 'camp',
        blurbType: 'camp',
    },
    {
        key: 'event.open-day.high.oneonone',
        template: PdfTemplateOpenDay,
        thumbnail: thumbnails.openDay,
        type: 'event',
        blurbType: 'openday',
    },
    {
        key: 'event.open-day.upper.oneonone',
        template: PdfTemplateOpenDay,
        thumbnail: thumbnails.openDay,
        type: 'event',
        blurbType: 'openday',
    },
    {
        key: 'event.open-day.lower.oneonone',
        template: PdfTemplateOpenDay,
        thumbnail: thumbnails.openDay,
        type: 'event',
        blurbType: 'openday',
    },
    // duplicated, and wrong id-> dd junior
    // {
    //     key: 'digital.discovery.basic.normal.2',
    //     template: PdfTemplateDigitalDiscovery,
    //     thumbnail: thumbnails.digitalDiscovery,
    //     type: 'course',
    //     blurbType: 'course',
    // },
    {
        key: 'digital.discovery.junior',
        template: PdfTemplateDigitalDiscovery,
        thumbnail: thumbnails.digitalDiscovery,
        type: 'course',
        blurbType: 'course',
    },
    // {            // fake subject
    //     key: 'robot.lego.ev3.advanced',
    //     template: PdfTemplateRobot,
    //     thumbnail: thumbnails.robot,
    //     type: 'course',
    //     blurbType: 'course',
    // },
    {
        key: 'robot.lego.wedo.2.space',
        template: PdfTemplateRobot,
        thumbnail: thumbnails.robot,
        type: 'camp',
        blurbType: 'camp',
    },
    {
        key: 'digital.roblox.hacker',
        template: PdfTemplateRoblox,
        thumbnail: thumbnails.roblox,
        type: 'camp',
        blurbType: 'camp',
    },
    {
        key: 'minecraft.heroes',
        template: PdfTemplateMinecraft,
        thumbnail: thumbnails.minecraft,
        type: 'camp',
        blurbType: 'camp',
    },
    {
        key: 'prog.ai.python',
        template: PdfTemplateAiPython,
        thumbnail: thumbnails.aiPython,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'prog.ai.basic',
        template: PdfTemplateAiBasic,
        thumbnail: thumbnails.aiBasic,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'prog.ai.basic.python',
        template: PdfTemplateAiBasic,
        thumbnail: thumbnails.aiBasic,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'prog.ai.python.1',
        template: PdfTemplateAiPython,
        thumbnail: thumbnails.aiPython,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'prog.ai.python.2',
        template: PdfTemplateAiPython,
        thumbnail: thumbnails.aiPython,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'event.camp-challenge',
        template: PdfTemplateCampChallengeDay,
        thumbnail: thumbnails.openDay,
        type: 'event',
        blurbType: 'campChallengeDay',
    },
    {
        key: 'prog.dev.master',
        template: PdfTemplateDevMaster,
        thumbnail: thumbnails.mix,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'prog.dev.master.python',
        template: PdfTemplateDevMaster,
        thumbnail: thumbnails.mix,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'explorer.junior.spike',
        template: PdfTemplateExplorerJuniorSpike,
        thumbnail: thumbnails.explorerJunior,
        type: 'camp',
        blurbType: 'camp',
    },
    {
        key: 'explorer.junior.wedo',
        template: PdfTemplateExplorerJuniorWedo,
        thumbnail: thumbnails.explorerJunior,
        type: 'camp',
        blurbType: 'camp',
    },
    {
        key: 'explorer.junior.beebot',
        template: PdfTemplateExplorerJuniorBeebot,
        thumbnail: thumbnails.explorerJunior,
        type: 'camp',
        blurbType: 'camp',
    },
    {
        key: 'explorer.creative',
        template: PdfTemplateExplorerCreative,
        thumbnail: thumbnails.explorerCreative,
        type: 'camp',
        blurbType: 'camp',
    },
    {
        key: 'explorer.gamer',
        template: PdfTemplateExplorerGamer,
        thumbnail: thumbnails.explorerGamer,
        type: 'camp',
        blurbType: 'camp',
    },
    {
        key: 'short.workshop.roblox',
        template: PdfTemplateRoblox,
        thumbnail: thumbnails.roblox,
        type: 'workshop',
        blurbType: 'workshop',
    },
    {
        key: 'short.workshop.minecraft',
        template: PdfTemplateMinecraft,
        thumbnail: thumbnails.minecraft,
        type: 'workshop',
        blurbType: 'workshop',
    },
    {
        key: 'short.workshop.hacker',
        template: PdfTemplateBlox,
        thumbnail: thumbnails.blox,
        type: 'workshop',
        blurbType: 'workshop',
    },
    {
        key: 'short.workshop.illustration',
        template: PdfTemplateWorkshopIllustration,
        thumbnail: thumbnails.illustrationWorkshop,
        type: 'workshop',
        blurbType: 'workshop',
    },
    {
        key: 'robot.lego.spike.python',
        template: PdfTemplateRobot,
        thumbnail: thumbnails.robot,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'digital.generative.ai',
        template: PdfTemplateAiBasic,
        thumbnail: thumbnails.genAI,
        type: 'workshop',
        blurbType: 'workshop',
    },
    {
        key: 'digital.generative.ai.camp',
        template: PdfTemplateAiBasic,
        thumbnail: thumbnails.genAI,
        type: 'camp',
        blurbType: 'camp',
    },
    {
        key: 'prog.mi.python.fast',
        template: PdfTemplateBlox,
        thumbnail: thumbnails.mix,
        type: 'course',
        blurbType: 'course',
    },
    {
        key: 'prog.mi.python.fast',
        template: PdfTemplateBlox,
        thumbnail: thumbnails.mix,
        type: 'camp',
        blurbType: 'camp',
    },    
    {
        key: 'prog.mi.python.normal',
        template: PdfTemplateMix,
        thumbnail: thumbnails.mix,
        type: 'course',
        blurbType: 'course',
    },
]
