import dayjs from 'dayjs'

const digitalMission2023WeeklyTasks = [
    {
        activeFrom: '2023-06-05T00:00:00Z',
        activeTo: '2023-06-12T00:00:00Z',
        name: 'digitalMission2023::weeklyTask01title',
        description: 'digitalMission2023::weeklyTask01description',
        image: 'https://api.scoolcode.com/profile-pictures/646c90a96c3786774087ae26/data',
        stat: 'memento-high-score-game',
        cta: {
            to: {
                pathname: '/app/high-score/64539e8c4bec3a816f774b30',
            },
            label: 'general::playGame',
        },
        requiredValue: 1,
        catalogItemId: '646b615296642b8385ba1829',
        match: {
            type: 'HIGH_SCORE_GAME',
            projectId: '64539e8c4bec3a816f774b30',
            minValue: 0,
        },
    },
    {
        activeFrom: '2023-06-12T00:00:00Z',
        activeTo: '2023-06-19T00:00:00Z',
        name: 'digitalMission2023::weeklyTask02title',
        description: 'digitalMission2023::weeklyTask02description',
        image: 'https://api.scoolcode.com/profile-pictures/646c90c62caa7d5487819c71/data',
        cta: {
            to: {
                pathname: '/app/my-projects/my-quizzes',
                hash: 'create-project',
                state: { type: 'mini-quiz' },
            },
            label: 'general::createAQuiz',
        },
        stat: 'shared-mini-quiz',
        requiredValue: 1,
        catalogItemId: '646b617296642be9a3ba1b21',
        match: {
            type: 'SHARED_MINI_QUIZ',
        },
    },
    {
        activeFrom: '2023-06-19T00:00:00Z',
        activeTo: '2023-06-26T00:00:00Z',
        name: 'digitalMission2023::weeklyTask03title',
        description: 'digitalMission2023::weeklyTask03description',
        image: 'https://api.scoolcode.com/profile-pictures/648727f919adb42dcb3dd5f7/data',
        cta: {
            to: {
                pathname: '/app/community/overview',
            },
            label: 'discoverProjects',
        },
        stat: 'cookie-given',
        requiredValue: 5,
        catalogItemId: '648727b6bc66d70eea3c8f4e',
        match: {
            type: 'COOKIE_GIVEN',
        },
    },
    {
        activeFrom: '2023-06-26T00:00:00Z',
        activeTo: '2023-07-03T00:00:00Z',
        name: 'digitalMission2023::weeklyTask04title',
        description: 'digitalMission2023::weeklyTask04description',
        image: 'https://api.scoolcode.com/profile-pictures/64882b5919adb46aea4270b3/data',
        cta: {
            to: {
                pathname: '/app/challenges/quizzes',
            },
            label: 'digitalMission2022::taksQuizTitle',
        },
        stat: 'correct-hard-quiz-answers',
        requiredValue: 5,
        catalogItemId: '64882af3bc66d7e5924a0b25',
        match: {
            type: 'QUIZ_QUESTION_CORRECTLY_ANSWERED',
            difficulty: 'hard',
        },
    },
    {
        activeFrom: '2023-07-03T00:00:00Z',
        activeTo: '2023-07-10T00:00:00Z',
        name: 'digitalMission2023::weeklyTask05title',
        description: 'digitalMission2023::weeklyTask05description',
        image: 'https://api.scoolcode.com/profile-pictures/64882b8219adb466a342714f/data',
        cta: {
            to: {
                pathname: '/app/challenges/mini-quests',
            },
            label: 'challenges::miniQuests',
        },
        stat: 'medium-mini-quests',
        requiredValue: 5,
        catalogItemId: '64882afdbc66d7c7534a0bc1',
        match: {
            type: 'COMPLETED_MINI_QUEST',
            difficulty: 'medium',
        },
    },
    {
        activeFrom: '2023-07-10T00:00:00Z',
        activeTo: '2023-07-17T00:00:00Z',
        name: 'digitalMission2023::weeklyTask06title',
        description: 'digitalMission2023::weeklyTask06description',
        image: 'https://api.scoolcode.com/profile-pictures/649c1d9419adb4e6de995377/data',
        stat: 'week-06-high-score-game',
        cta: {
            to: {
                pathname: '/app/high-score/64a3c9c49a4b9369361f4691',
            },
            label: 'general::playGame',
        },
        requiredValue: 1,
        catalogItemId: '649c1d4bd75b497dafeb1a2f',
        match: {
            type: 'HIGH_SCORE_GAME',
            projectId: '64a3c9c49a4b9369361f4691',
            minValue: 1000,
        },
    },
    {
        activeFrom: '2023-07-17T00:00:00Z',
        activeTo: '2023-07-24T00:00:00Z',
        name: 'digitalMission2023::weeklyTask07title',
        description: 'digitalMission2023::weeklyTask07description',
        image: 'https://api.scoolcode.com/profile-pictures/64abf547ebbbe42d153c05d1/data',
        stat: 'profile-updates',
        cta: {
            to: {
                pathname: '/app/settings/profile',
            },
            label: 'profile::editProfile',
        },
        requiredValue: 1,
        catalogItemId: '64abf1416a7c784b820dbb15',
        match: {
            type: 'UPDATE_PROFILE',
        },
    },
    {
        activeFrom: '2023-07-24T00:00:00Z',
        activeTo: '2023-07-31T00:00:00Z',
        name: 'digitalMission2023::weeklyTask08title',
        description: 'digitalMission2023::weeklyTask08description',
        image: 'https://api.scoolcode.com/profile-pictures/64b51ace3f11116cbdc5709a/data',
        stat: 'feed-votes',
        cta: {
            to: {
                pathname: '/app/welcome',
            },
            label: 'general::vote',
            hideInFeed: true,
        },
        requiredValue: 5,
        catalogItemId: '64b51aaac98dcd4689ed645e',
        match: {
            type: 'FEED_VOTE',
            voteCatalogItems: [
                '64afddc62cc6c18f34a24840',
                '64afdb6da4d2d8fba91e7e2e',
                '64afda1b3655c43c66e2c0fd',
                '64afd8c5a4d2d872f91e4b21',
                '64afd602a4d2d87fa11e2018',
            ],
        },
    },
    {
        activeFrom: '2023-07-31T00:00:00Z',
        activeTo: '2023-08-07T00:00:00Z',
        name: 'digitalMission2023::weeklyTask09title',
        description: 'digitalMission2023::weeklyTask09description',
        image: 'https://api.scoolcode.com/profile-pictures/64bf87057e356a7982174bc3/data',
        stat: 'followings',
        cta: {
            to: {
                pathname: '/app/community/discover',
            },
            label: 'discoverProjects',
        },
        requiredValue: 3,
        catalogItemId: '64bf80ef4833e63e92ab48c6',
        match: {
            type: 'FOLLOWING',
        },
    },
    {
        activeFrom: '2023-08-07T00:00:00Z',
        activeTo: '2023-08-14T00:00:00Z',
        name: 'digitalMission2023::weeklyTask10title',
        description: 'digitalMission2023::weeklyTask10description',
        image: 'https://api.scoolcode.com/profile-pictures/64ca145020d6e87f7eb19d91/data',
        stat: 'distinct-high-score-games',
        cta: {
            to: {
                pathname: '/app/challenges/high-score',
            },
            label: 'challenges::highScoreGames',
        },
        requiredValue: 3,
        catalogItemId: '64bf80f9da3f379150dfae7e',
        match: {
            type: 'DISTINCT_HIGH_SCORE_GAMES',
        },
    },
    {
        activeFrom: '2023-08-14T00:00:00Z',
        activeTo: '2023-08-21T00:00:00Z',
        name: 'digitalMission2023::weeklyTask11title01',
        description: 'digitalMission2023::weeklyTask11description01',
        image: 'https://api.scoolcode.com/profile-pictures/64d3c0dc7e356aa4f741efb8/data',
        stat: 'given-cookie-to-low-cookie-projects',
        cta: {
            to: {
                pathname: '/app/community/discover',
            },
            label: 'discoverProjects',
        },
        requiredValue: 1,
        catalogItemId: '64bf810491163803d07b1e8f',
        match: {
            type: 'COOKIE_GIVEN_TO_LOW_COOKIE_PROJECT'
        },
    },
    {
        activeFrom: '2023-08-21T00:00:00Z',
        activeTo: '2023-08-28T00:00:00Z',
        name: 'digitalMission2023::weeklyTask12title',
        description: 'digitalMission2023::weeklyTask12description',
        image: 'https://api.scoolcode.com/profile-pictures/64dcab88ed11984a4896af50/data',
        stat: 'dm2023-quiz-streak-6',
        cta: {
            to: {
                pathname: '/app/challenges/quizzes',
            },
            label: 'courses.takeQuiz',
        },
        requiredValue: 1,
        catalogItemId: '64bf810b4833e6ec9aab4b3e',
        match: {
            type: 'QUIZ_STREAK'
        },
    },
    {
        activeFrom: '2023-08-28T00:00:00Z',
        activeTo: '2023-09-04T00:00:00Z',
        name: 'digitalMission2023::weeklyTask13title',
        description: 'digitalMission2023::weeklyTask13description',
        image: 'https://api.scoolcode.com/profile-pictures/64dcabaf1b17a4dbdd6a0b7f/data',
        stat: 'dm2023-follow-trainers',
        cta: {
            to: {
                pathname: '/app/community/discover',
                search: "view=only-trainers"
            },
            label: '2023teachersDayNewsfeedPost::cta',
        },
        requiredValue: 2,
        catalogItemId: '64bf81134833e65118ab4bf5',
        match: {
            type: 'FOLLOW_TRAINERS'
        },
    },
    {
        activeFrom: '2023-09-04T00:00:00Z',
        activeTo: '2023-09-11T00:00:00Z',
        name: 'digitalMission2023::weeklyTask14title',
        description: 'digitalMission2023::weeklyTask14description',
        image: 'https://api.scoolcode.com/profile-pictures/64ef162683ae4a28d2fa635d/data',
        stat: 'dm2023-but-why-quiz',
        cta: {
            to: {
                pathname: '/app/challenges/quizzes/64d2248c6f44ce983b80f774'
            },
            label: 'butWhyQuiz::title',
        },
        requiredValue: 1,
        catalogItemId: '64bf811dda3f376f5ddfb1dc',
        match: {
            type: 'BUT_WHY_QUIZ'
        },
    },
    {
        activeFrom: '2023-09-11T00:00:00Z',
        activeTo: '2023-09-18T00:00:00Z',
        name: 'digitalMission2023::weeklyTask15title',
        description: 'digitalMission2023::weeklyTask15description',
        image: 'https://api.scoolcode.com/profile-pictures/64ef1813445f372bc950349a/data',
        stat: 'dm2023-play-community',
        cta: {
            to: {
                pathname: '/app/community/overview'
            },
            label: 'discoverProjects',
        },
        requiredValue: 10,
        catalogItemId: '64ef17e9ebaad471c47b22e4',
        match: {
            type: 'PLAY_COMMUNITY'
        },
    },
]

const normalizedDigitalMission2023WeeklyTasks = digitalMission2023WeeklyTasks.map(definition => ({
    ...definition,
    activeFrom: dayjs(definition.activeFrom),
    activeTo: dayjs(definition.activeTo),
}))

export function getActiveDigitalMission2023WeeklyTask() {
    const now = dayjs()

    for (const task of normalizedDigitalMission2023WeeklyTasks) {
        if (now.isAfter(task.activeFrom) && now.isBefore(task.activeTo)) {
            return task
        }
    }

    return null
}

export type DigitalMission2023WeeklyTask = typeof normalizedDigitalMission2023WeeklyTasks[number]
