import { useUserState } from 'contexts/UserContext'

export const allGrants = [
    { product: 'edup.universe', feature: 'share-project' },
    { product: 'edup.universe', feature: 'share-project-user-consent' },
    { product: 'edup.universe', feature: 'content.digital-mission' },
    { product: 'edup.universe', feature: 'content.certificates' },
    { product: 'edup.universe', feature: 'content.certificates-all' },
    { product: 'edup.universe', feature: 'share-project-without-approval' },
    { product: 'edup.universe', feature: 'show-vote-full-results' },
    { product: 'edup.universe', feature: 'content.trainer-contest-nomination' },
    { product: 'edup.universe', feature: 'content.onboarding-event' },
    { product: 'edup.universe', feature: 'high-score-rooms' },
    { product: 'edup.universe', feature: 'create-high-score-room' },
    { product: 'edup.universe', feature: 'connect-discord-account' },
    { product: 'edup.universe', feature: 'follow-users' },
    { product: 'edup.universe', feature: 'limited-app.latest-challenges' },
    { product: 'edup.universe', feature: 'limited-app.view-community-projects' },
    { product: 'edup.universe', feature: 'content.challenges.onboarding-points' },
    { product: 'edup.universe', feature: 'content.challenges.open-day-participation' },
    { product: 'edup.universe', feature: 'view-stats' },
    { product: 'edup.events', feature: 'read' },
    { product: 'edup.universe', feature: 'report-classroom' },
    { product: 'edup.universe', feature: 'mini-quiz-create-and-share' },
    { product: 'edup.universe', feature: 'mini-quiz-discover' },
    { product: 'edup.quiz', feature: 'login' },
    { product: 'edup.universe', feature: 'change-bio-without-approval' },
    { product: 'edup.scoolcode.ide', feature: 'create-project.blox' },
    { product: 'edup.scoolcode.ide', feature: 'create-project.mix' },
    { product: 'edup.scoolcode.ide', feature: 'language-python' },
    { product: 'edup.scoolcode.ide', feature: 'language-stagescript' },
    { product: 'edup.scoolcode.ide', feature: 'offboarding.mix' },
    { product: 'edup.scoolcode.ide', feature: 'advanced-filtering' },
    { product: 'edup.scoolcode.ide', feature: 'advanced-project-thumbnail' },
    { product: 'edup.scoolcode.ide', feature: 'reset-challenge-set' },
    { product: 'edup.scoolcode.ide', feature: 'move-to-production' },
    { product: 'edup.scoolcode.ide', feature: 'advanced-duplication' },
    { product: 'edup', feature: 'change-ui-language' },
    { product: 'edup', feature: 'all-languages' },
    { product: 'edup', feature: 'change-email' },
    { product: 'edup', feature: 'change-default-school' },
    { product: 'edup', feature: 'license-user' },
    { product: 'edup', feature: 'trainer-user' },
    { product: 'edup.administration', feature: 'accounts' },
    { product: 'edup.activity-score', feature: 'advanced-read' },
    { product: 'edup.universe', feature: 'liveops-recorder' },
    { product: 'edup.universe', feature: 'content.challenges.ofc' },
    { product: 'edup', feature: 'product-demo' },
    { product: 'edup.universe', feature: 'social-sharing' },
    { product: 'edup.universe', feature: 'redeem-secret-code' },
    { product: 'edup.trainer-portal', feature: 'news' },
    { product: 'edup.universe', feature: 'content.among-us' },
    { product: 'edup.universe', feature: 'give-golden-cookie' },
    { product: 'edup.universe', feature: 'content.scooler-mission-2022-themes' },
    { product: 'edup.universe', feature: 'content.scooler-mission-2022-themes-ro' },
    { product: 'edup.universe', feature: 'project-labels' },
    { product: 'edup.universe', feature: 'content.camp-challenge-2023' },
] as const

export type Grant = typeof allGrants[number]
export type GrantProduct = Grant['product']
export type GrantFeature = Grant['feature']
export type NormalizedGrantList = Partial<Record<GrantProduct, Partial<Record<GrantFeature, Array<any>>>>>

export const hasGrant = (grants: NormalizedGrantList) => (grant: Grant | GrantFeature) => {
    if (typeof grant === 'string') {
        return !!grants['edup.universe']?.[grant]?.length
    } else {
        return !!grants[grant.product]?.[grant.feature]?.length
    }
}

export const getGrants = (grants: NormalizedGrantList) => (grant: Grant | GrantFeature) => {
    if (typeof grant === 'string') {
        return grants['edup.universe']?.[grant] || [];
    } else {
        return grants[grant.product]?.[grant.feature] || [];
    }
}

export const grantToString = (grant: Grant) => `${grant.product}/${grant.feature}`

export function useHasGrant() {
    const { grants } = useUserState()
    return hasGrant(grants)
}

