import { client } from 'redux-socket-client'
import {
    REQUEST_LEADERBOARD,
    REQUEST_SCORE_LIST,
    REQUEST_STATISTICS,
    DISABLE_ALL_LEADERBOARDS_FOR_PROJECT,
    INCREMENT_ACTIVITY_SCORE,
    SET_LEADERBOARD_LENGTH,
    REQUEST_ROOMS,
} from '../constants'

const statistics = [
    { group: 'Seasons/DigitalMission', stat: 'season-point' },
    { group: 'Seasons/DigitalMission:2021', stat: 'season-point' },
    { group: 'Seasons/DigitalMission:2021', stat: 'cookie-received' },
    { group: 'Seasons/DigitalMission:2021', stat: 'cookie-given' },
    { group: 'Seasons/DigitalMission:2021', stat: 'followings' },
    { group: 'Seasons/DigitalMission:2021', stat: 'trainer-followings' },
    { group: 'Seasons/DigitalMission:2021', stat: 'completed-mini-challenges' },
    { group: '@SYSTEM', stat: 'weekly-high-score-trophy-gold' },
    { group: '@SYSTEM', stat: 'weekly-high-score-trophy-silver' },
    { group: '@SYSTEM', stat: 'weekly-high-score-trophy-bronze' },
    { group: '@SYSTEM', stat: 'weekly-high-score-trophy-platinum' },
    { group: '@SYSTEM', stat: 'cookie-treasures-collected' },
    { group: 'Seasons/DigitalMission:2021', stat: 'weekly-top100-presence' },
    { group: 'Seasons/DigitalMission:2021', stat: 'view-given' },
    { group: 'Seasons/DigitalMission:2021', stat: 'feed-votes' },
    { group: 'Seasons/DigitalMission:2021', stat: 'cookie-treasures-collected' },
    { group: 'Seasons/DigitalMission:2021', stat: 'trainer-contest-cookie-given' },
    { group: '@SYSTEM/TotalCurrency', stat: 'cookie-received' },
    { group: '@SYSTEM/TotalCurrency', stat: 'golden-cookie-received' },
    { group: '@SYSTEM', stat: 'like-received' },
    { group: '@SYSTEM', stat: 'view-received' },
    { group: '@SYSTEM', stat: 'view-given' },
    { group: '@SYSTEM', stat: 'followings' },
    { group: '@SYSTEM', stat: 'trainer-followings' },
    { group: '@SYSTEM', stat: 'quizzes-finished' },
    { group: '@SYSTEM', stat: 'high-score-game-participation' },
    { group: '@SYSTEM', stat: 'completed-mini-challenges' },
    { group: '@SYSTEM', stat: 'public-view-received' },
    { group: '@SYSTEM/TotalCurrency', stat: 'cookie-given' },
    { group: 'OpenDayOnboarding', stat: 'points' },
] as const

type Statistic<T extends { group: string; stat: string }> = T extends any ? `${T['group']}:${T['stat']}` : never
export type StatisticName = Statistic<typeof statistics[number]>

export const requestCommonStats = client(() => ({
    type: REQUEST_STATISTICS,
    payload: {
        statistics,
    },
    subscribe: true,
}))

export const requestStats = client((stats: { group: string; stat: string }[]) => ({
    type: REQUEST_STATISTICS,
    payload: {
        statistics: stats,
    },
    subscribe: true,
}))

export const incrementActivityScore = client((activity: string) => ({
    type: INCREMENT_ACTIVITY_SCORE,
    payload: {
        activity,
    },
}))

export const requestQuizStats = client(() => ({
    type: REQUEST_STATISTICS,
    payload: {
        statistics: [
            { group: 'quiz-stats', stat: 'correct-responses/classroom' },
            { group: 'quiz-stats', stat: 'correct-responses/self-host' },
            { group: 'quiz-stats', stat: 'total-responses/classroom' },
            { group: 'quiz-stats', stat: 'total-responses/self-host' },
            { group: 'quiz-stats', stat: 'sessions/classroom' },
            { group: 'quiz-stats', stat: 'sessions/self-host' },
        ],
    },
    subscribe: true,
}))

export const requestScoreList = client((project: string) => ({
    type: REQUEST_SCORE_LIST,
    payload: {
        project,
    },
}))

export const enableLeaderboard = client((group: string, stat: string, version?: string) => ({
    type: REQUEST_LEADERBOARD,
    subscribe: true,
    payload: {
        group,
        stat,
        version,
    },
}))

export const enableProjectLeaderboard = (project: string, stat: string, room?: string) => {
    const roomStatPrefix = room ? `@ROOM/${room}:` : ''
    return enableLeaderboard(`${roomStatPrefix}@PROJECT/${project}:score`, stat, room ? undefined : 'latest')
}

export const setLeaderboardLength = client((project: string, stat: string, length: number, room?: string) => {
    const roomStatPrefix = room ? `@ROOM/${room}:` : ''
    return {
        type: SET_LEADERBOARD_LENGTH,
        payload: {
            group: `${roomStatPrefix}@PROJECT/${project}:score`,
            stat,
            length,
            version: room ? undefined : 'latest',
        },
    }
})

export const disableAllLeaderboardsForProject = (projectId: string) => ({
    type: DISABLE_ALL_LEADERBOARDS_FOR_PROJECT,
    payload: {
        projectId,
    },
})

export const disableLeaderboard = client((group: string, stat: string, version?: string) => ({
    type: REQUEST_LEADERBOARD,
    unsubscribe: true,
    payload: {
        group,
        stat,
        version,
    },
}))

export const enableQuizTemplateLeaderboard = client((templateId: string) => ({
    type: REQUEST_LEADERBOARD,
    subscribe: true,
    payload: {
        group: `quiz/template/total-score`,
        stat: templateId,
    },
}))

export const disableQuizTemplateLeaderboard = client((templateId: string) => ({
    type: REQUEST_LEADERBOARD,
    unsubscribe: true,
    payload: {
        group: `quiz/template/total-score`,
        stat: templateId,
    },
}))

export const requestRooms = client(() => ({
    type: REQUEST_ROOMS,
}))
