export interface CustomCert {
    certName: string
    certCode: string
    date: Date
    schoolName?: string
    texts: {
        section_first?: string
        section_second?: string
        blurb?: string | [string, Object]
    }
}

type PlaceType = '1st' | '2nd' | '3rd' | '4th' | '5th' | undefined

export const drawingContest2020ScoolroundParticipation: CustomCert = {
    certName: 'drawingContest2020::participation',
    certCode: 'drawUnit',
    date: new Date(2020, 10, 30),
    texts: {
        section_first: 'certificate::text1::DrawingContest2020::participation',
        section_second: 'certificate::text2::DrawingContest2020::participation',
    },
}
export const drawingContest2021ScoolroundParticipation: CustomCert = {
    certName: 'drawingContest2021::participation',
    certCode: 'draw2021-base',
    date: new Date(2021, 11, 8),
    texts: {
        section_first: 'certificate::text1::DrawingContest2021::participation',
        section_second: 'certificate::text2::DrawingContest2021::participation',
    },
}

export const drawingContest2020ScoolroundPlaced = (school: string, place: PlaceType): CustomCert => ({
    certName: 'drawingContest2020::schoolround',
    certCode: 'drawUnit',
    date: new Date(2020, 10, 30),
    texts: {
        section_first: 'certificate::text1::DrawingContest2020::schoolround',
        blurb: ['certificate::blurb::DrawingContest2020::schoolround' + place, { school }],
        section_second: 'certificate::text2::DrawingContest2020::schoolround',
    },
})
export const drawingContest2021ScoolroundPlaced = (school: string, place: PlaceType): CustomCert => ({
    certName: 'drawingContest2021::schoolround',
    certCode: 'draw2021-base',
    date: new Date(2021, 11, 8),
    texts: {
        section_first: 'certificate::text1::DrawingContest2021::schoolround',
        blurb: ['certificate::blurb::DrawingContest2021::schoolround' + place, { school }],
        section_second: 'certificate::text2::DrawingContest2021::schoolround',
    },
})

export const drawingContest2020ScoolroundTrainerChoice = (school: string): CustomCert => ({
    certName: 'drawingContest2020::schoolround',
    certCode: 'drawUnit',
    date: new Date(2020, 10, 30),
    texts: {
        section_first: 'certificate::text1::DrawingContest2020::schoolroundtrainerschoice',
        blurb: ['certificate::blurb::DrawingContest2020::schoolroundtrainerschoice', { school }],
        section_second: 'certificate::text2::DrawingContest2020::schoolround',
    },
})
export const drawingContest2021ScoolroundTrainerChoice = (school: string): CustomCert => ({
    certName: 'drawingContest2021::schoolround',
    certCode: 'draw2021-base',
    date: new Date(2021, 11, 8),
    texts: {
        section_first: 'certificate::text1::DrawingContest2021::schoolroundtrainerschoice',
        blurb: ['certificate::blurb::DrawingContest2021::schoolroundtrainerschoice', { school }],
        section_second: 'certificate::text2::DrawingContest2021::schoolround',
    },
})

export const drawingContest2020National = (school: string, place: PlaceType): CustomCert => ({
    certName: 'drawingContest2020::nationalround',
    certCode: 'drawNational',
    date: new Date(2020, 10, 30),
    texts: {
        section_first: 'certificate::text1::DrawingContest2020::schoolround',
        blurb: ['certificate::blurb::DrawingContest2020::nationalround' + place, { school }],
        section_second: 'certificate::text2::DrawingContest2020::schoolround',
    },
})

export const drawingContest2021National = (school: string, place: PlaceType): CustomCert => ({
    certName: 'drawingContest2021::nationalround',
    certCode: 'draw2021-base',
    date: new Date(2021, 11, 8),
    texts: {
        section_first: 'certificate::text1::DrawingContest2021::schoolround',
        blurb: ['certificate::blurb::DrawingContest2021::nationalround' + place, { school }],
        section_second: 'certificate::text2::DrawingContest2021::schoolround',
    },
})

export const drawingContest2020InterationalParticipation: CustomCert = {
    certName: 'drawingContest2020::internationalround',
    certCode: 'drawInternParticipant',
    date: new Date(2020, 11, 14),
    texts: {
        section_first: 'certificate::text1::DrawingContest2020::internationaltop10',
        blurb: 'certificate::blurb::DrawingContest2020::internationaltop10',
        section_second: 'certificate::text2::DrawingContest2020::internationaltop10',
    },
}

export const drawingContest2021InterationalParticipation: CustomCert = {
    certName: 'drawingContest2021::internationalround',
    certCode: 'draw2021-base',
    date: new Date(2022, 2, 17),
    texts: {
        section_first: 'certificate::text1::DrawingContest2021::internationaltop10',
        blurb: 'certificate::blurb::DrawingContest2021::internationaltop10',
        section_second: 'certificate::text2::DrawingContest2021::internationaltop10',
    },
}

export const drawingContest2020JuniorDesigner = (place: PlaceType): CustomCert => ({
    certName: 'drawingContest2020::juniordesigner',
    certCode: 'drawInternJuniorDesigner',
    date: new Date(2020, 11, 14),
    texts: {
        section_first: 'certificate::text1::DrawingContest2020::juniordesigner',
        blurb: 'certificate::blurb::DrawingContest2020::juniordesigner' + place,
        section_second: 'certificate::text2::DrawingContest2020::juniordesigner',
    },
})

export const drawingContest2021JuniorDesigner = (place: PlaceType): CustomCert => ({
    certName: 'drawingContest2021::juniordesigner',
    certCode: 'draw2021-base',
    date: new Date(2022, 2, 17),
    texts: {
        section_first: 'certificate::text1::DrawingContest2021::juniordesigner',
        blurb: 'certificate::blurb::DrawingContest2021::juniordesigner' + place,
        section_second: 'certificate::text2::DrawingContest2021::juniordesigner',
    },
})

export const drawingContest2022ScoolroundParticipation: CustomCert = {
    certName: 'drawingContest2022::schoolround',
    certCode: 'drawing-contest-2022',
    date: new Date(2022, 10, 25),
    texts: {
        section_first: 'certificate::text1::DrawingContest2022::participation',
        section_second: 'certificate::text2::DrawingContest2022::participation',
    },
}

export const drawingContest2022ScoolroundPlaced = (school: string, place: PlaceType): CustomCert => ({
    certName: 'drawingContest2022::schoolround',
    certCode: 'drawing-contest-2022',
    date: new Date(2022, 10, 25),
    texts: {
        section_first: 'certificate::text1::DrawingContest2022::schoolround',
        blurb: ['certificate::blurb::DrawingContest2022::schoolround' + place, { school }],
        section_second: 'certificate::text2::DrawingContest2022::schoolround',
    },
})

export const drawingContest2022ScoolroundTrainerChoice = (school: string): CustomCert => ({
    certName: 'drawingContest2022::schoolround',
    certCode: 'drawing-contest-2022',
    date: new Date(2022, 10, 25),
    texts: {
        section_first: 'certificate::text1::DrawingContest2022::schoolroundtrainerschoice',
        blurb: ['certificate::blurb::DrawingContest2022::schoolroundtrainerschoice', { school }],
        section_second: 'certificate::text2::DrawingContest2022::schoolround',
    },
})

export const drawingContest2022National = (school: string, place: PlaceType): CustomCert => ({
    certName: 'drawingContest2022::nationalround',
    certCode: 'drawing-contest-2022',
    date: new Date(2022, 11, 1),
    texts: {
        section_first: 'certificate::text1::DrawingContest2022::schoolround',
        blurb: ['certificate::blurb::DrawingContest2022::nationalround' + place, { school }],
        section_second: 'certificate::text2::DrawingContest2022::schoolround',
    },
})

export const drawingContest2022InterationalParticipation: CustomCert = {
    certName: 'drawingContest2022::internationalround',
    certCode: 'drawing-contest-2022',
    date: new Date(2022, 11, 16),
    texts: {
        section_first: 'certificate::text1::DrawingContest2022::internationaltop10',
        blurb: 'certificate::blurb::DrawingContest2022::internationaltop10',
    },
}

export const drawingContest2022JuniorDesigner = (place: PlaceType): CustomCert => ({
    certName: 'drawingContest2022::juniordesigner',
    certCode: 'drawing-contest-2022',
    date: new Date(2022, 11, 16),
    texts: {
        section_first: 'certificate::text1::DrawingContest2022::internationaltop10',
        blurb: 'certificate::blurb::DrawingContest2022::juniordesigner' + place,
    },
})

export const drawingContest2023ScoolroundParticipation: CustomCert = {
    certName: 'drawingContest2023::schoolround',
    certCode: 'drawing-contest-2023',
    date: new Date(2023, 10, 14),
    texts: {
        section_first: 'certificate::text1::DrawingContest2023::participation',
        section_second: 'certificate::text2::DrawingContest2023::participation',
    },
}

export const drawingContest2023ScoolroundPlaced = (school: string, place: PlaceType): CustomCert => ({
    certName: 'drawingContest2023::schoolround',
    certCode: 'drawing-contest-2023',
    date: new Date(2023, 10, 14),
    texts: {
        section_first: 'certificate::text1::DrawingContest2023::schoolround',
        blurb: ['certificate::blurb::DrawingContest2022::schoolround' + place, { school }],
        section_second: 'certificate::text2::DrawingContest2023::schoolround',
    },
})

export const drawingContest2023ScoolroundTrainerChoice = (school: string): CustomCert => ({
    certName: 'drawingContest2023::schoolround',
    certCode: 'drawing-contest-2023',
    date: new Date(2023, 10, 14),
    texts: {
        section_first: 'certificate::text1::DrawingContest2023::schoolroundtrainerschoice',
        blurb: ['certificate::blurb::DrawingContest2022::schoolroundtrainerschoice', { school }],
        section_second: 'certificate::text2::DrawingContest2022::schoolround',
    },
})

export const drawingContest2023National = (school: string, place: PlaceType): CustomCert => ({
    certName: 'drawingContest2023::nationalround',
    certCode: 'drawing-contest-2023',
    date: new Date(2023, 11, 6),
    texts: {
        section_first: 'certificate::text1::DrawingContest2023::schoolround',
        blurb: ['certificate::blurb::DrawingContest2022::nationalround' + place, { school }],
        section_second: 'certificate::text2::DrawingContest2022::schoolround',
    },
})

export const drawingContest2023InterationalParticipation: CustomCert = {
    certName: 'drawingContest2023::internationalround',
    certCode: 'drawing-contest-2023',
    date: new Date(2023, 11, 16),
    texts: {
        section_first: 'certificate::text1::DrawingContest2023::internationaltop10',
        blurb: 'certificate::blurb::DrawingContest2022::internationaltop10',
    },
}

export const drawingContest2023JuniorDesigner = (place: PlaceType): CustomCert => ({
    certName: 'drawingContest2023::juniordesigner',
    certCode: 'drawing-contest-2023',
    date: new Date(2023, 11, 16),
    texts: {
        section_first: 'certificate::text1::DrawingContest2023::internationaltop10',
        blurb: 'certificate::blurb::DrawingContest2022::juniordesigner' + place,
    },
})

