import {
    Checkbox as MuiCheckBox,
    CheckboxProps as MuiCheckBoxProps,
    FormControlLabel as MuiFormControlLabel,
} from '@material-ui/core'
import styled from 'styled-components/macro'
import React from 'react'

interface CheckBoxProps extends MuiCheckBoxProps {
    label?: string
}

export const CheckBox: React.FC<CheckBoxProps> = ({ label, ...rest }) => {
    return (
        <div>
            <MuiFormControlLabel
                control={
                    // @ts-ignore component prop typing is not working correctly
                    <StyledCheckbox {...rest} color="inherit" />
                }
                label={label}
            />
        </div>
    )
}

const StyledCheckbox = styled(MuiCheckBox)`
    color: ${props => props.theme.palette.primary.main};
`
