import React from 'react'
import { useNotifications } from 'features/notifications'
import { certificateTypeColorMap, downloadFile, formatDate } from './certificateHelpers'
import styled from 'styled-components/macro'
import { PdfGenerator } from './PdfGenerator'
import { Loader } from 'components/Loader'
import { Text } from 'components/Text'
import { ActionCard } from 'components/action-card'
import { Img } from 'components/Img'
import logiscoolLogo from './logiscool-logo.png'
import { useTranslate } from 'lib/i18n/useTranslate'
import { useUserState } from 'contexts/UserContext'
import { LocalisationKey } from 'lib/i18n'
import { CertificateRibbon } from './CertificateRibbon'
import { CertificateType, SubjectInfo } from './certificateTypes'
import { CertificateDialog } from './CertifiateDialog'

interface CertificateItemProps {
    fullName: string
    certificateInfo: SubjectInfo
    isOpening: boolean
    setOpening: (isOpening: boolean) => void
    userCountry?: string
}

export const CertificateItem: React.FC<CertificateItemProps> = ({
    fullName,
    certificateInfo,
    isOpening,
    setOpening,
    userCountry,
}) => {
    const { subjectInfo, translations } = certificateInfo
    const t = useTranslate()
    const { i18n } = useUserState()
    const { notify } = useNotifications()
    const [status, setStatus] = React.useState<'idle' | 'generating-pdf' | 'open-dialog'>('idle')
    const [imgData, setImgData] = React.useState<{ img: string; blob: Blob }>()

    const handleClick = () => {
        if (status === 'idle' && !isOpening) {
            setStatus('generating-pdf')
            setOpening(true)
        }
    }

    const handleGenerateSucces = (blob: Blob, imgData: string) => {
        setImgData({ img: imgData, blob })
        setStatus('open-dialog')
    }

    const handleDownload = () => {
        if (!imgData) return
        let filename = `Logiscool ${t('certificate::simpletitle')}`

        const finishedAt = formatDate(subjectInfo.lastDay, i18n.language)
        filename = `Logiscool ${t('certificate::simpletitle')} – ${t(subjectInfo.key as LocalisationKey)} ${finishedAt}`

        filename = filename.replace(/\./g, '')
        downloadFile(imgData.blob, filename)
    }

    const finishedAt = formatDate(subjectInfo.lastDay, i18n.language)
    const { color, highlightColor } = certificateTypeColorMap[subjectInfo.type]

    return (
        <>
            <Root onClick={handleClick} certificateType={subjectInfo.type}>
                <Inner>
                    <StyledCertificateRibbon color={color} highlightColor={highlightColor} />
                    <TextWrap>
                        <Title>{translations.name}</Title>
                        <Text variant="caption">{finishedAt}</Text>
                    </TextWrap>
                    <ThumbnailWrap>
                        <Thumbnail src={subjectInfo.thumbnail || logiscoolLogo} />
                    </ThumbnailWrap>
                </Inner>
                {status === 'generating-pdf' && (
                    <LoaderWrap>
                        <Loader size="1rem" />
                    </LoaderWrap>
                )}
            </Root>
            {status === 'generating-pdf' && (
                <PdfGenerator
                    subject={subjectInfo}
                    fullName={fullName}
                    userCountry={userCountry}
                    locale={i18n.locale}
                    language={i18n.language}
                    onSuccess={handleGenerateSucces}
                    onError={() => {
                        setStatus('idle')
                        notify(t('error::somethingWentWrong'), { variant: 'error' })
                    }}
                />
            )}
            {imgData && (
                <CertificateDialog
                    open={status === 'open-dialog'}
                    close={() => {
                        setStatus('idle')
                        setOpening(false)
                    }}
                    imgData={imgData.img}
                    title={translations.name}
                    download={handleDownload}
                />
            )}
        </>
    )
}

const StyledCertificateRibbon = styled(CertificateRibbon)`
    position: relative;
    top: -${props => props.theme.spacing(3)};
    width: 100%;
    filter: drop-shadow(0 5px 3px rgba(0, 0, 0, 0.25));
    transition: transform ${props => props.theme.transitions.duration.standard}ms;
`

const Root = styled(ActionCard)<{ certificateType: CertificateType }>`
    position: relative;
    overflow: visible;
    &:hover,
    &:focus-visible {
        ${StyledCertificateRibbon} {
            transform: translateY(-3px);
        }
    }
`

const LoaderWrap = styled.div`
    position: absolute;
    top: ${props => props.theme.spacing(2)};
    right: ${props => props.theme.spacing(2)};
`

const TextWrap = styled.div`
    align-self: center;
`

const ThumbnailWrap = styled.div`
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
    padding: ${props => props.theme.spacing(0, 3, 3, 0)};
`

const Thumbnail = styled(Img)`
    display: block;
    width: 7rem;
    height: 7rem;
    object-fit: contain;
    object-position: bottom right;
`

const Inner = styled.div`
    display: grid;
    grid-template-columns: 3.25rem 8fr 6fr;
    gap: ${props => props.theme.spacing(3)};
    padding-inline-start: ${props => props.theme.spacing(4)};
`

const Title = styled(Text)`
    font-weight: 600;
    line-height: 1.2;
`
