import { Button } from 'components/Button'
import { Card, CardContent } from 'components/card'
import { SecretCodeUnlocker } from 'features/secret-code'
import { createHashDialog } from 'hooks/createHashDialog'
import React from 'react'
import { Link } from 'react-router-dom'
import { Dialog, DialogTitle } from 'components/dialog'
import { useMyChallengeSetsQuery } from 'features/scoolcode-challenge/data/scoolcodeChallengeQueries'
import { ErrorMessage } from 'components/ErrorMessage'
import { Loader } from 'components/Loader'
import { useTranslate } from 'lib/i18n/useTranslate'
import { ApiTypes } from 'lib/api'
import lockedBgImg from '../media/locked-bg.jpg'
import styled from 'styled-components/macro'
import { Text } from 'components/Text'
import { TextBody } from 'components/layout/TextBody'
import { PlayIcon, UnlockIcon } from 'components/icons'
import { PoorMansSuspenseTicker, usePoorMansSuspenseTick } from 'hooks/usePoorMansSuspense'
import { getIdeUrl } from 'lib/api/helpers'

const [unlockProjectTo, UnlockProjectController] = createHashDialog('unlock-project')

const OPEN_DAY_CHALLENGE_SETS = [
    '5d7b9f748eaaff001d2ff3fc',
    '5d9461d2057622001cb7fd73',
    '5d9470f29c8710001d8be729',
    '5fbe672dae37a100087a4349',
    '612ddbd67cd882fd5498a64b',
    '612de28b7cd882244d98a651',
    '612769f730fd7b3cbf008bab',
    '65a11d68b4e021a30fc57e9b'
]

function isOpenDayChallengeSet(set: ApiTypes['challenge-set']) {
    return OPEN_DAY_CHALLENGE_SETS.includes(set.id)
}

interface OpenDayChallengeProps extends PoorMansSuspenseTicker {}

export const OpenDayChallenge: React.FC<OpenDayChallengeProps> = ({ onReady }) => {
    const { data: challengeSets, status, refetch } = useMyChallengeSetsQuery()
    usePoorMansSuspenseTick(status === 'success', onReady)
    const t = useTranslate()

    if (status === 'error') {
        return <ErrorMessage />
    }

    if (!challengeSets) {
        return <Loader />
    }

    const challengeSet = challengeSets.data.filter(isOpenDayChallengeSet)[0]

    return (
        <Card>
            <Grid>
                <CardContent>
                    <TextBody>
                        <Text variant="h1" component="h3">
                            {t('OpendayClassroom::Openday::title')}
                        </Text>
                        <Text>{t('OpendayClassroom::Openday::description')}</Text>
                    </TextBody>
                </CardContent>

                {challengeSet ? (
                    <ProjectRoot as="a" href={getChallengeSetUrl(challengeSet)} target="_blank" rel="noreferrer">
                        <ProjectOverlay>
                            <Button startIcon={<PlayIcon />}>{t('projects::openProject')}</Button>
                        </ProjectOverlay>
                        <ProjectImg src={challengeSet.coverUrl} />
                    </ProjectRoot>
                ) : (
                    <>
                        <ProjectRoot to={unlockProjectTo}>
                            <ProjectOverlay>
                                <Button startIcon={<UnlockIcon />}>{t('projects::unlockProject')}</Button>
                            </ProjectOverlay>
                            <ProjectImg src={lockedBgImg} />
                        </ProjectRoot>
                        <UnlockProjectController>
                            {context => (
                                <Dialog {...context}>
                                    <DialogTitle>{t('projects::unlockProject')}</DialogTitle>
                                    <SecretCodeUnlocker
                                        primaryLabel={t('projects::unlockProject')}
                                        close={context.onClose}
                                        customProcessor={async () => {
                                            const res = await refetch()
                                            const challengeSet = res && res.data.filter(isOpenDayChallengeSet)[0]
                                            if (!challengeSet) {
                                                return false
                                            }
                                            window.open(getChallengeSetUrl(challengeSet))
                                            context.onClose()
                                            return true
                                        }}
                                    />
                                </Dialog>
                            )}
                        </UnlockProjectController>
                    </>
                )}
            </Grid>
        </Card>
    )
}

const getChallengeSetUrl = (challengeSet: ApiTypes['challenge-set']) =>
    getIdeUrl(`/challenge-sets/${challengeSet.id}/challenges/1`)

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: ${props => props.theme.spacing(6)};
    ${props => props.theme.breakpoints.down('sm')} {
        grid-template-columns: auto;
        grid-gap: ${props => props.theme.spacing(4)};
    }
`

const ProjectRoot = styled(Link)`
    display: block;
    position: relative;
    overflow: hidden;
`

const ProjectImg = styled.img.attrs({
    width: 1136,
    height: 640,
})`
    display: block;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: ${props => props.theme.palette.divider};
`

const ProjectOverlay = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(35, 35, 35, 0.85);
`
